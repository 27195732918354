import _Vue from "vue";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
  isSupported,
} from "firebase/messaging";
import logger from "@/plugins/logger";
import { Notifications } from "./Notifications";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import AuthModule from "@/store/modules/Auth";
import { Device } from "@capacitor/device";
import {
  Storage as StorageLib,
  Notifications as NotificationLib,
} from "@/hooks";
import NotificationSystem from "@/store/modules/NotificationSystem";

export interface fbHookType {
  fbConfig: FirebaseOptions;
  getFbApp: () => FirebaseApp | null;
  getFbMessaging: () => Messaging | null;
  initWebApp: () => void;
  subscribeOnMessage: () => void;
  // fbAnalytics: Analytics;
  getTokenAsync: (
    serviceWorkerRegistration: ServiceWorkerRegistration
  ) => Promise<string>;
  getPWAToken: () => Promise<string | null>;
  isSupported: () => Promise<boolean>;
  addMobileListeners: () => Promise<void>;
}

export const firebaseHook = (): fbHookType => {
  // const firebaseConfig: FirebaseOptions = {
  //   apiKey: "AIzaSyBnmUxWrVBSi1H8bzmsH1MS_gTwwp-Gm3U",
  //   authDomain: "zero8-e6ca8.firebaseapp.com",
  //   projectId: "zero8-e6ca8",
  //   storageBucket: "zero8-e6ca8.appspot.com",
  //   messagingSenderId: "714877315059",
  //   appId: "1:714877315059:web:94cfce5f025722b9411486",
  //   measurementId: "G-5WJYM239ST",
  // };
  const firebaseConfig = {
    apiKey: "AIzaSyBnmUxWrVBSi1H8bzmsH1MS_gTwwp-Gm3U",
    authDomain: "zero8-e6ca8.firebaseapp.com",
    projectId: "zero8-e6ca8",
    storageBucket: "zero8-e6ca8.appspot.com",
    messagingSenderId: "714877315059",
    appId: "1:714877315059:web:5f502c2945a4267a411486",
    measurementId: "G-JG0C2X4LP4",
  };

  let firebaseApp: FirebaseApp | null = null;
  let messaging: Messaging | null = null;

  const initWebApp = () => {
    firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
    logger.log("Init test: ", firebaseApp, messaging);
    subscribeOnMessage();
    //
  };
  const getPWAToken = async (): Promise<string | null> => {
    const temp = await navigator.serviceWorker.getRegistration();
    let token: string | null = null;

    logger.log("temp: ", temp);
    if (temp) {
      logger.log("Requesting permission...");
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        logger.log("Notification permission granted.");
        initWebApp();
        token = await getTokenAsync(temp);
      } else {
        logger.log("Notification permission denied.");
        token = null;
      }
    } else {
      logger.log("No registration for service worker");
      token = null;
    }
    return Promise.resolve(token);
  };

  const subscribeOnMessage = (): void => {
    const msg = getFbMessaging();
    if (msg == null) {
      logger.error("Cannot subscribe to on message == null");
      return;
    }
    onMessage(msg, (payload) => {
      logger.log("Message received. ", payload);
      NotificationSystem.addToUnreadNotifications({
        title: payload.notification?.title || "Title Missing",
        id: Number(payload.messageId),
        body: payload.notification?.body || "Text missing",
        date: new Date().toDateString(),
        time: new Date().toTimeString(),
      });
      // Notifications.createAlarmNotification({
      //   title: payload.notification?.title,
      //   id: payload.messageId,
      //   body: payload.notification?.body || "Text missing",
      // });
    });
  };
  // const analytics = getAnalytics(firebaseApp);
  const getFbApp = (): FirebaseApp | null => {
    return firebaseApp;
  };
  const getFbMessaging = (): Messaging | null => {
    return messaging;
  };

  const getTokenAsync = async (
    serviceWorkerRegistration: ServiceWorkerRegistration
  ): Promise<string> => {
    try {
      const msg = getFbMessaging();
      if (msg == null) {
        logger.log("Firebase Messaging == null");
        return Promise.reject("Firebase Messaging == null");
      }
      logger.log("Going to get token", serviceWorkerRegistration);
      const token = await getToken(msg, {
        vapidKey:
          "BB_gG03mz7Gr8cI1p0sMtNrWcPodDJM8zF6ptuk6XzjYiVabfcf320fr0-fIIkJgNTjI7TmU7TLGsV0eGh0D0F4",
        serviceWorkerRegistration: serviceWorkerRegistration,
      }).catch((err) => {
        logger.log("GET_TOKEN err: ", err);
        return Promise.reject(err);
      });

      if (token) {
        logger.log("Registration Token Available", token);
      } else {
        logger.log(
          "No registration token available. Request permission to generate one."
        );
      }
      return Promise.resolve(token);
    } catch (err) {
      logger.log("GET_TOKEN_ASYNC err: ", err);
      return Promise.reject(err);
    }
  };
  const addMobileListeners = async () => {
    await PushNotifications.addListener("registration", async (token) => {
      console.info("Registration token: ", token.value, AuthModule.getUser);
      await StorageLib.setFcmToken(token.value);
      NotificationSystem.setFirebaseToken(token.value);
      //set token in local storage/vuex
      // if user doPushNotifications
      console.info("Registration token after: ", AuthModule.getUser);
      if (AuthModule.getUser != null) {
        logger.log("doPushNotif parent block");
        await NotificationLib.doPushNotifications(token.value);
      } else {
        // this should not happen though test
        // NotificationLib.createWarningNotification(
        //   `Does not register after getProfile. ${token.value}`
        // );
      }
    });

    await PushNotifications.addListener("registrationError", (err) => {
      logger.error("Registration error: ", err.error);
      NotificationLib.createErrorNotification(
        "Mobile Push notification registration failed. Contact the dev team."
      );
    });
    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotificationSchema) => {
        logger.log(
          "Notification in foreground: ",
          JSON.stringify(notification)
        );
        // vibrate

        // create notification based on X Y Z
        const dev = await Device.getInfo();
        if (dev) {
          if (dev.platform == "android") {
            logger.log("Android 11 and up", dev.osVersion);
            const temp = Number(dev.osVersion);
            if (dev.osVersion && Number.isNaN(temp) == false && temp >= 11) {
              return Promise.resolve();
            }
          }
        }

        return Promise.resolve();
      }
    );
    // Method called when tapping on a notification
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        logger.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
      }
    );
  };
  return {
    fbConfig: firebaseConfig,
    getFbMessaging,
    getFbApp,
    initWebApp,
    subscribeOnMessage,
    // fbAnalytics: analytics,
    getTokenAsync,
    isSupported,
    getPWAToken,
    addMobileListeners,
  };
};
const fbHook = firebaseHook();
export default fbHook;
