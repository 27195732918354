/* eslint-disable @typescript-eslint/no-explicit-any */
import { axiosInstance } from "@/plugins/axios";
import {
  ManageUser,
  ManageUsersWrapper,
  ManageUserViewModel,
  AuthToken,
  LoginResponse,
  UserDetails,
  SuggestedUsernames,
} from "models";
import AuthModule from "@/store/modules/Auth";
import dayjs from "dayjs";
import { Storage } from "@/hooks";
import { UserNameModel } from "@/models/username.model";
import logger from "@/plugins/logger";

const User = () => {
  const baseUrl = "/api/userManagers";
  const getUsers = async (): Promise<ManageUsersWrapper> => {
    const res = await axiosInstance.get(baseUrl + "/getUser");
    return Promise.resolve(res.data);
  };

  const editUser = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + `/editUser`, data);
    return Promise.resolve(res.data);
  };

  const addUser = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + `/addUser`, data);
    return Promise.resolve(res.data);
  };

  const deleteUser = async (
    manageUserID: string
  ): Promise<ManageUserViewModel[]> => {
    const res = await axiosInstance.post(
      baseUrl + `/deleteUser?manageUserID=${manageUserID}`
    );
    return Promise.resolve(res.data);
  };

  const getUserPicturesByID = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getUserPicturesByID?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const getUserSignatureByID = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/getUserSignatureByID?id=${id}`
    );
    return Promise.resolve(res.data);
  };

  const downloadUserPicture = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/downloadUserPicture?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  const downloadUserFile = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/downloadUserFile?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "image/jpeg" + "application/pdf" + "image/png",
        },
        responseType: "blob",
      }
    );
    return Promise.resolve(res.data);
  };

  /**
   * Returns a JS object representation of a Javascript Web Token from its common encoded
   * string form.
   *
   * @template T the expected shape of the parsed token
   * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
   * @returns {(T | undefined)} an object-representation of the token
   * or undefined if parsing failed
   */
  const getParsedJwt = <T extends object = { [k: string]: string | number }>(
    token: string
  ): T | undefined => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch {
      return undefined;
    }
  };
  const validateJWTExp = (val: number | string) => {
    const tokenTime = dayjs.unix(typeof val == "string" ? Number(val) : val);
    const currentTime = dayjs();
    logger.log(
      "(Validate) Token : ",
      tokenTime > currentTime,
      tokenTime,
      currentTime
    );
    if (tokenTime > currentTime) {
      return true;
    }
    return false;
  };
  const processNewJWT = async (val: UserDetails) => {
    const token = getParsedJwt(val.accessToken);
    logger.warn("JWT Token ", token);
    if (token && token.exp) {
      const valid = validateJWTExp(token.exp);
      if (valid) {
        AuthModule.setUser(val);
        await Storage.setLogin(val);
        return Promise.resolve(true);
      }
    }
    AuthModule.setUser(null);
    await Storage.removeLogin();
    return Promise.resolve(false);
  };

  const confirmUsernameExist = async (
    data: UserNameModel
  ): Promise<SuggestedUsernames> => {
    const res = await axiosInstance.post(
      baseUrl + `/ConfirmUsernameExist`,
      data
    );
    return Promise.resolve(res.data);
  };

  return {
    getUsers,
    editUser,
    deleteUser,
    addUser,
    getUserPicturesByID,
    getUserSignatureByID,
    downloadUserFile,
    downloadUserPicture,
    processNewJWT,
    getParsedJwt,
    confirmUsernameExist,
  };
};
const temp = User();
export default temp;
