
import { Component, Vue, Watch } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Ammo, CRUDFunctionality, Downloader, Incidents } from "@/hooks";
import { Ammunition, Incident } from "models";
import manageItemModal from "@/views/registers/ammunition/AmmunitionAddEdit.vue";
import additionalInfoModal from "@/views/registers/ammunition/AmmunitionAdditional.vue";
import dayjs from "dayjs";
import IncidentModal from "@/views/manage/incidents/IncidentsModal.vue";

@Component({
  components: {
    JsonExcel,
    manageItemModal,
    additionalInfoModal,
    IncidentModal,
  },
})
export default class ManageAmmunition extends Vue {
  items: Ammunition[] = [];
  itemsFiltered: Ammunition[] = [];
  loading = false;
  search = "";
  sortDesc = true;
  sortBy = "bookedOut";
  manageItem = false;
  additional = false;
  refresh = false;
  additionalData = {
    tableData: {},
    title: "",
  };
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Calibre", value: "calibre" },
    { text: "Currently Booked Out", value: "bookedOut" },
    { text: "Used in Incident", value: "usedInIncident" },
    { text: "Not Booked/Reported", value: "usedAmmunition" },
    { text: "Adjustments", value: "adjustment" },
    { text: "Total Purchased", value: "totalQuantity" },
    { text: "Total Available", value: "totalAvailable" },
  ];
  expReports: {
    Calibre: string;
    "Currently Booked Out": string;
    "Used in Incident": string;
    "Not Booked/Reported": string;
    Adjustments: string;
    "Total Purchased": string;
    "Total Available": string;
  }[] = [];
  expFields = {
    Calibre: "calibre",
    "Currently Booked Out": "bookedOut",
    "Used in Incident": "usedInIncident",
    "Not Booked/Reported": "usedAmmunition",
    Adjustments: "adjustment",
    "Total Purchased": "totalQuantity",
    "Total Available": "totalAvailable",
  };
  incidentsModal: {
    open: boolean;
    selectedItem: Ammunition | null;
    type: "Ammo" | "Firearm" | "ARO";
  } = {
    open: false,
    selectedItem: null,
    type: "Ammo",
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    //logger.log("Name: ", name, has);
    return has;
  }

  @Watch("refresh", { immediate: true })
  modalDataChanged(): void {
    if (this.refresh == true) {
      this.loadItems();
    }
  }

  openAddItem(): void {
    this.refresh = false;
    this.manageItem = true;
  }

  async openAdditionalInfo(i: Ammunition): Promise<void> {
    this.additionalData.title = i.calibre;
    try {
      const res = await Ammo.GetAmmunitionListByCalibre(i.calibre);
      this.additionalData.tableData = res.ammunitions;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.additional = true;
    }
  }

  updateReportsFiltered(event: Ammunition[]) {
    this.itemsFiltered = event ? (event as Ammunition[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.itemsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "Calibre", dataKey: "Calibre" },
        { header: "Currently Booked Out", dataKey: "Currently Booked Out" },
        { header: "Used in Incident", dataKey: "Used in Incident" },
        { header: "Not Booked/Reported", dataKey: "Not Booked/Reported" },
        { header: "Adjustments", dataKey: "Adjustments" },
        { header: "Total Purchased", dataKey: "Total Purchased" },
        { header: "Total Available", dataKey: "Total Available" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Ammunition (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
        horizontalPageBreakRepeat: "Calibre",
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async loadItems(): Promise<void> {
    try {
      this.loading = true;
      const res = await Ammo.getAmmunition();
      this.items = res;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
    this.expReports = this.populateExportData(this.items);
  }

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  populateExportData(items: Ammunition[]) {
    return items.map((elem: Ammunition) => {
      return {
        Calibre: elem.calibre != null ? elem.calibre : "N/A",
        "Currently Booked Out":
          elem.bookedOut != null ? String(elem.bookedOut) : "N/A",
        "Used in Incident":
          elem.usedInIncident != null ? String(elem.usedInIncident) : "N/A",
        "Not Booked/Reported":
          elem.usedAmmunition != null ? String(elem.usedAmmunition) : "N/A",
        Adjustments: elem.adjustment != null ? String(elem.adjustment) : "N/A",
        "Total Purchased":
          elem.totalQuantity != null ? String(elem.totalQuantity) : "N/A",
        "Total Available":
          elem.totalAvailable != null ? String(elem.totalAvailable) : "N/A",
      };
    });
  }

  openIncidentsForAmmo(item: Ammunition) {
    this.incidentsModal.selectedItem = item;
    this.incidentsModal.open = true;
  }
}
