
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { Incidents, CRUDFunctionality } from "@/hooks";
import dayjs from "dayjs";
import logger from "@/plugins/logger";

@Component({
  filters: {
    fileName: function (value: string) {
      if (value.length > 30) {
        const front = value.substring(0, 20);
        const end = value.substring(value.length - 10);
        return front + "..." + end;
      } else {
        return value;
      }
    },
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class manageIncidentFileModal extends Vue {
  @PropSync("open") manageFile!: boolean;
  @PropSync("refresh") refreshData!: boolean;
  @Prop() readonly data!: any;
  files: any[] = [];
  images: any[] = [];
  carouselLoading = false;
  carouselItems: { src: string; id: number }[] = [];
  uploadI = false;
  uploadF = false;
  validI = false;
  validF = false;
  newImage = "";
  newFile = "";
  newFuploaded = false;
  newImageUploaded = false;
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    //logger.log("Name: ", name, has);
    return has;
  }

  @Watch("data", { immediate: false })
  async modalDataChanged(): Promise<void> {
    logger.log("File Modal Data:", this.data);
    await this.getFiles(this.data.id);
  }

  getCarouselItems(): void {
    this.carouselLoading = true;
    this.carouselItems = [];

    if (this.images.length > 0) {
      this.images.forEach(async (elem, i) => {
        if (elem.mimeType != null) {
          var type = "";
          var resI = await Incidents.downLoadImage(elem.id)
            .catch((err) => {
              logger.log("Error Historic Incidents: ", err);
              return Promise.reject(err);
            })
            .then((response: any): string => {
              type = response.type;
              const url = window.URL.createObjectURL(
                new Blob([response], {
                  type: response.type,
                })
              );
              return url;
            });
          if (type != "application/pdf") {
            this.carouselItems.push({ src: resI, id: i + 1 });
          }
        } else {
          this.carouselItems.push({
            src: require("@/assets/FileNotFound.png"),
            id: i + 1,
          });
        }
      });
    }

    if (this.files.length > 0) {
      this.files.forEach(async (elem, i) => {
        if (elem.mimeType != null) {
          var type = "";
          var resF = await Incidents.downLoadFile(elem.id)
            .catch((err) => {
              logger.log("Error Historic Incidents: ", err);
              return Promise.reject(err);
            })
            .then((response: any): string => {
              type = response.type;
              const url = window.URL.createObjectURL(
                new Blob([response], { type: response.type })
              );
              return url;
            });
          if (type != "application/pdf") {
            this.carouselItems.push({ src: resF, id: i + 1 });
          }
        } else {
          this.carouselItems.push({
            src: require("@/assets/FileNotFound.png"),
            id: i + 1,
          });
        }
      });
    }

    logger.log("C Items: ", this.carouselItems);
    this.carouselLoading = false;
  }

  async getFiles(id: number): Promise<void> {
    this.images = [];
    this.files = [];
    try {
      const res = await Incidents.getFiles(id);

      var images =
        res.incidentList != null ? res.incidentList.incidentPictures : null;
      var files =
        res.incidentList != null ? res.incidentList.incidentFiles : null;

      if (images != null) {
        images.forEach((elem: any) => {
          this.images.push(elem);
        });
      }
      logger.log("Images", this.images);

      if (files != null) {
        files.forEach((elem: any) => {
          this.files.push(elem);
        });
      }
      logger.log("Files", this.files);
      this.getCarouselItems();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  openUploadImage(): void {
    this.uploadF = false;
    this.uploadI = !this.uploadI;
  }

  openUploadFile(): void {
    this.uploadI = false;
    this.uploadF = !this.uploadF;
  }

  checkUploads(): void {
    if (this.newFuploaded == true) {
      this.refreshData = true;
    } else if (this.newImageUploaded == true) {
      this.refreshData = true;
    } else {
      this.refreshData = false;
    }
  }

  async uploadFile(): Promise<void> {
    var formData = new FormData();
    formData.append("incidentID", this.data.id);
    formData.append("file", this.newFile);
    try {
      const res = await Incidents.addIncidentFile(formData);

      await this.getFiles(this.data.id);
      this.newFuploaded = true;
      this.$notifCreator.createSuccessNotification(
        "Incident File Uploaded Succesfully!"
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.newFile = "";
      this.uploadF = false;
    }
  }

  async uploadImage(): Promise<void> {
    var formData = new FormData();
    formData.append("incidentID", this.data.id);
    formData.append("image", this.newImage);
    try {
      const res = await Incidents.addIncidentImage(formData);

      await this.getFiles(this.data.id);
      this.newImageUploaded = true;
      this.$notifCreator.createSuccessNotification(
        "Incident Image Uploaded Succesfully!"
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.newImage = "";
      this.uploadI = false;
    }
  }

  async download(i: any, type: string): Promise<any> {
    try {
      if (i.mimeType != null) {
        if (type == "image") {
          await Incidents.downLoadImage(i.id).then((response: any) => {
            var type = "image/jpeg";
            if (response.type == "image/jpeg") {
              type = "image/jpeg";
            } else if (response.type == "application/pdf") {
              type = "application/pdf";
            } else if (response.type == "image/png") {
              type = "image/png";
            }
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: type,
              })
            );
            window.open(url);
            /* logger.log(response.type); */
          });
        } else if (type == "file") {
          await Incidents.downLoadFile(i.id).then((response: any) => {
            var type = "image/jpeg";
            if (response.type == "image/jpeg") {
              type = "image/jpeg";
            } else if (response.type == "application/pdf") {
              type = "application/pdf";
            } else if (response.type == "image/png") {
              type = "image/png";
            }
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: type,
              })
            );
            window.open(url);
            /* logger.log(response.type); */
          });
        }
      } else if (i.mimeType == null) {
        this.$notifCreator.createErrorNotification("Can't find Image.");
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
