import AuthModule from "@/store/modules/Auth";
import { ROLE as RolesEnum } from "@/enums/roles";

const CRUDFunctionality = () => {
  const allowedActions: {
    [key: number]: string[];
  } = {
    [RolesEnum["Super Admin"]]: ["addUser", "editUser", "deleteUser"],
    [RolesEnum["Control Room"]]: [
      "addTeam",
      "editTeam",
      "deleteTeam",
      "addIncident",
      "editIncident",
      "deleteIncident",
      "addFirearmPermit",
      "editFirearmPermit",
      "deleteFirearmPermit",
      "addShift",
      "editShift",
      "deleteShift",
    ],
    [RolesEnum["Responsible Person"]]: [
      "addUser", // TODO: Confirm this permission should be allowed
      "editUser", // TODO: Confirm this permission should be allowed
      // "deleteUser",
      "addDocument",
      "editDocument",
      "deleteDocument",
      "addARO",
      "editARO",
      "deleteARO",
      "addFirearm",
      "editFirearm",
      "deleteFirearm",
      "addFirearmPermit",
      "editFirearmPermit",
      "deleteFirearmPermit",
      "addAmmo",
      "addTeam",
      "editTeam",
      "deleteTeam",
      "addIncident",
      "editIncident",
      "deleteIncident",
      "addShift",
      "editShift",
      "deleteShift",
      "editArchive",
    ],
    [RolesEnum["Shift Supervisor"]]: [
      "addTeam",
      "editTeam",
      "deleteTeam",
      "addIncident",
      "editIncident",
      "deleteIncident",
      "addFirearmPermit",
      "editFirearmPermit",
      "deleteFirearmPermit",
      "addShift",
      "editShift",
      "deleteShift",
    ],
    [RolesEnum["Arm Response Officer"]]: [
      "addIncident",
      /* "editIncident",
      "deleteIncident", */
      "addPBEntry",
    ],
    [RolesEnum["Security Firm Admin"]]: [
      "addUser",
      "editUser",
      "deleteUser",
      "addDocument",
      "editDocument",
      "deleteDocument",
      "addARO",
      "editARO",
      "deleteARO",
      "addFirearm",
      "editFirearm",
      "deleteFirearm",
      "addFirearmPermit",
      "editFirearmPermit",
      "deleteFirearmPermit",
      "addAmmo",
      "addTeam",
      "editTeam",
      "deleteTeam",
      "addIncident",
      "editIncident",
      "deleteIncident",
      "addShift",
      "editShift",
      "deleteShift",
      "editArchive",
      /* "deletePBEntry", */
    ],
    [RolesEnum["Security Firm Sub Admin"]]: [], // Seems Unused
  };

  const hasAccess = (name: string | null | undefined): boolean => {
    if (name == null || name == undefined) {
      return false;
    }
    // logger.log("Checking has access hook: ", name, AuthModule.getUserRoles);
    let valid = false;
    if (AuthModule.getUserRoles.length > 0) {
      // logger.log("User roles foreach ", AuthModule.getUserRoles);
      AuthModule.getUserRoles.forEach((role) => {
        const temp = allowedActions[role.roleId];
        // logger.log("User roles foreach ", AuthModule.getUserRoles);
        if (temp.length > 0) {
          temp.forEach((action) => {
            // logger.log("Action : ", route);
            if (name.includes(action)) {
              valid = true;
            }
          });
        }
      });
    }
    return valid;
  };

  return {
    hasAccess,
    RolesEnum,
    allowedActions,
  };
};
const temp = CRUDFunctionality();
export default temp;
