
import { Component, Vue, Watch } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Incidents, CRUDFunctionality, Downloader } from "@/hooks";
import { Incident } from "models";
import manageItemModal from "@/views/manage/incidents/IncidentsAddEdit.vue";
import manageItemFiles from "@/views/manage/incidents/IncidentFiles.vue";
import dayjs from "dayjs";
import logger from "@/plugins/logger";

@Component({
  components: {
    JsonExcel,
    manageItemModal,
    manageItemFiles,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class Manage_Incidents extends Vue {
  items: Incident[] = [];
  itemsFiltered: Incident[] = [];
  loading = false;
  historic = false;
  search = "";
  sortDesc = true;
  sortBy = "date";
  expanded = [];
  deleteModal = false;
  dischargeData!: any;
  showDischarge = false;
  imageModal = false;
  fileModalData = {};
  incidentImages: any[] = [];
  manageItem = false;
  manageItemName = "";
  manageItemId = 0;
  refresh = false;
  modalData: {
    type: string;
    field: any;
  } = {
    type: "",
    field: {},
  };
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
  };
  headers = [
    { text: "", value: "actions", sortable: false },
    { text: "Incident No", value: "incidentNumber" },
    { text: "Incident Type", value: "incidentType.name" },
    { text: "Date", value: "date" },
    { text: "Time", value: "time" },
    { text: "ARO/SO", value: "armResponseOfficer.name" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "Incident No": string;
    "Incident Type": string;
    Date: string;
    Time: string;
    "ARO/SO": string;
    Firearm: string;
    "Ammunition Calibre": string;
    "Ammunition Quantity": string;
    "Number of Rounds": string;
    Description: string;
    "Objects Involved": string;
    "Police Station": string;
    "SAP Case Number": string;
    "SAP 13 NUmber": string;
    "SAP 14 Number": string;
    "Investigative Officer": string;
    "Officer Contact": string;
    "Next Action Steps": string;
    File: string;
  }[] = [];
  expFields = {
    "Incident No": "Incident No",
    "Incident Type": "Incident Type",
    Date: "Date",
    Time: "Time",
    "ARO/SO": "ARO/SO",
    Firearm: "Firearm",
    "Ammunition Calibre": "Ammunition Calibre",
    "Ammunition Quantity": "Ammunition Quantity",
    "Number of Rounds": "Number of Rounds",
    Description: "Description",
    "Objects Involved": "Objects Involved",
    "Police Station": "Police Station",
    "SAP Case Number": "SAP Case Number",
    "SAP 13 NUmber": "SAP 13 NUmber",
    "SAP 14 Number": "SAP 14 Number",
    "Investigative Officer": "Investigative Officer",
    "Officer Contact": "Officer Contact",
    "Next Action Steps": "Next Action Steps",
    File: "File",
  };

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    //logger.log("Name: ", name, has);
    return has;
  }

  @Watch("refresh", { immediate: true })
  modalDataChanged(): void {
    if (this.refresh == true) {
      this.loadItems();
    }
  }

  /* @Watch("imageModal", { immediate: false })
  imageModalDataChanged(): void {
    this.loadItems();
  } */

  openEditItem(i: Incident): void {
    this.modalData.type = "edit";
    this.modalData.field = i;
    this.refresh = false;
    this.manageItem = true;
  }

  openAddItem(): void {
    this.modalData.type = "add";
    this.modalData.field = {};
    this.refresh = false;
    this.manageItem = true;
  }

  openDeleteItem(i: Incident): void {
    this.manageItemName =
      i.incidentNumber + i.armResponseOfficer != null
        ? "(" +
          i.armResponseOfficer.name +
          " " +
          i.armResponseOfficer.surname +
          ")"
        : "";
    this.manageItemId = i.id;
    this.deleteModal = true;
  }

  async openViewImage(i: Incident): Promise<void> {
    this.fileModalData = i;
    this.refresh = false;
    this.imageModal = true;
  }

  dischargeItem(i: Incident): void {
    logger.log("Discharge Item", i);
    this.dischargeData = null;
    this.dischargeData = i;
    this.showDischarge = true;
    this.discharge();
  }

  discharge(): void {
    let divToPrint, newWin;
    divToPrint = document.getElementById("divPrintIncidentDischarge");

    divToPrint!.style.display = "block";

    newWin = window.open("");
    newWin?.document.open();
    this.showDischarge = false;

    newWin?.document.write(divToPrint!.innerHTML);
    newWin?.location.reload();
    newWin?.focus();
    newWin?.print();
    newWin?.close();

    divToPrint!.style.display = "none";

    //window.alert("Something Went Wrong, Please try again.");
  }

  async deleteItem(): Promise<void> {
    try {
      const res = await Incidents.deleteIncident(this.manageItemId);
      this.$notifCreator.createSuccessNotification(
        "Incident Deleted Succesfully."
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteModal = false;
      this.loadItems();
    }
  }
  updateReportsFiltered(event: any) {
    this.itemsFiltered = event ? (event as any[]) : this.items;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.itemsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      const columns = [
        { header: "Incident No", dataKey: "Incident No" },
        { header: "Incident Type", dataKey: "Incident Type" },
        { header: "Date", dataKey: "Date" },
        { header: "Time", dataKey: "Time" },
        { header: "ARO/SO", dataKey: "ARO/SO" },
        { header: "Firearm", dataKey: "Firearm" },
        { header: "Ammunition Calibre", dataKey: "Ammunition Calibre" },
        { header: "Ammunition Quantity", dataKey: "Ammunition Quantity" },
        { header: "Number of Rounds", dataKey: "Physical Address" },
        { header: "Description", dataKey: "Description" },
        { header: "Objects Involved", dataKey: "Objects Involved" },
        { header: "Police Station", dataKey: "Police Station" },
        { header: "SAP Case Number", dataKey: "SAP Case Number" },
        { header: "SAP 13 NUmber", dataKey: "SAP 13 NUmber" },
        { header: "SAP 14 Number", dataKey: "SAP 14 Number" },
        { header: "Investigative Officer", dataKey: "Investigative Officer" },
        { header: "Officer Contact", dataKey: "Officer Contact" },
        { header: "Next Action Steps", dataKey: "Next Action Steps" },
        { header: "File", dataKey: "File" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `${
          this.historic == true ? "Historic Incidents" : "Incidents"
        } (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @Watch("historic", { immediate: true })
  async historicCheck(): Promise<void> {
    await this.loadItems();
  }

  async loadItems(): Promise<void> {
    this.items = [];
    try {
      this.loading = true;
      if (this.historic == true) {
        const res = await Incidents.getHistoricIncidents();
        this.items = res.incidents;
      } else {
        const res = await Incidents.getIncidents();
        this.items = res;
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
    this.expReports = this.populateExportData(this.items);
  }

  async mounted(): Promise<void> {
    await this.loadItems();
  }

  populateExportData(items: Incident[]) {
    return items.map((elem: Incident) => {
      return {
        "Incident No":
          elem.incidentNumber != null ? elem.incidentNumber : "N/A",
        "Incident Type":
          elem.incidentType != null ? elem.incidentType.name : "N/A",
        Date: elem.date != null ? dayjs(elem.date).format("YYYY-MM-DD") : "N/A",

        Time: elem.time != null ? elem.time : "N/A",
        "ARO/SO":
          elem.armResponseOfficer != null
            ? elem.armResponseOfficer.name +
              ", " +
              elem.armResponseOfficer.surname
            : "N/A",

        Firearm:
          elem.firearm != null
            ? elem.firearm.serialNumber +
              " (" +
              elem.firearm.make +
              ", " +
              elem.firearm.model +
              ", " +
              elem.firearm.typeStr +
              ", " +
              elem.firearm.expiryDateStr +
              ")"
            : "N/A",
        "Ammunition Calibre":
          elem.ammunition != null ? elem.ammunition.calibre : "N/A",
        "Ammunition Quantity":
          elem.ammoAmount != null ? String(elem.ammoAmount) : "N/A",
        "Number of Rounds":
          elem.roundDischarged != null ? String(elem.roundDischarged) : "N/A",
        Description: elem.description != null ? elem.description : "N/A",
        "Objects Involved":
          elem.objectInvolved != null ? elem.objectInvolved : "N/A",
        "Police Station":
          elem.policeStation != null ? elem.policeStation : "N/A",
        "SAP Case Number":
          elem.sapCaseNumber != null ? elem.sapCaseNumber : "N/A",
        "SAP 13 NUmber": elem.saP13Number != null ? elem.saP13Number : "N/A",
        "SAP 14 Number": elem.saP14Number != null ? elem.saP14Number : "N/A",
        "Investigative Officer":
          elem.investigationOfficer != null ? elem.investigationOfficer : "N/A",
        "Officer Contact":
          elem.investigationOfficerContact != null
            ? elem.investigationOfficerContact
            : "N/A",
        "Next Action Steps":
          elem.nextActionSteps != null ? elem.nextActionSteps : "N/A",
        File: " ",
      };
    });
  }
}
