
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { VueSignaturePad } from "vue-signature-pad";
import { CRUDFunctionality } from "@/hooks";
import Auth from "@/store/modules/Auth";
import logger from "@/plugins/logger";

@Component({
  components: {
    VueSignaturePad,
  },
})
export default class Signature extends Vue {
  @Prop({ default: "add" }) readonly type!: "add" | "edit";
  @Prop({ default: true }) readonly showDisclaimer!: boolean;
  @Prop({ default: false }) readonly required!: boolean;
  @PropSync("clearSignature") clearSignaturePad: boolean | undefined;
  signed = false;
  onBegin() {
    if (this.$refs.signature) {
      this.signed = true;
    }
  }
  onEnd() {
    this.$nextTick(function () {
      if (this.$refs.signature) {
        this.$refs.signature.resizeCanvas();
      }
    });
  }
  clear() {
    if (this.$refs.signature) {
      this.$refs.signature.clearSignature();
      this.signed = false;
      if (this.clearSignaturePad == true) {
        this.clearSignaturePad = false;
      }
      this.$emit("cancel");
    }
  }
  undo() {
    if (this.$refs.signature) {
      this.$refs.signature.undoSignature();
      if (this.$refs.signature.isEmpty()) {
        this.signed = false;
        this.$emit("cancel");
      }
    }
  }
  submit() {
    if (this.$refs.signature) {
      const { isEmpty, data } = this.$refs.signature!.saveSignature();
      if (!isEmpty) {
        logger.log(isEmpty);
        logger.log(data);
        this.$emit("submit", data);
      }
    }
  }

  @Watch("clearSignaturePad", { immediate: true })
  clearSignatureChanged(): void {
    logger.log("clearSignature:", this.clearSignaturePad);
    if (this.clearSignaturePad && this.clearSignaturePad == true) {
      this.clear();
    }
  }

  shouldDisplayForRole(name: string | undefined): boolean {
    const has = CRUDFunctionality.hasAccess(name);
    logger.log("!has: ", !has);

    if (Auth.userOverview!.roleID.toString() == "6") {
      return false;
    } else {
      return !has;
    }
  }
}
