
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Dash from "@/store/modules/Dash";
import { Navigation, Storage } from "@/hooks";

@Component
export default class NavDrawer extends Vue {
  flipDrawer(trust: boolean): void {
    // logger.log("Nav flip me please ", trust, this.appDrawer);
    Dash.setAppDrawer(trust);
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }

  get getIncidentProxy(): boolean {
    return AuthModule.getIncidentProxy;
  }

  get getReportProxy(): boolean {
    return AuthModule.getReportProxy;
  }

  selected = null;
  async logout() {
    // logger.log("Logged out");
    AuthModule.removeLoggedIn();
    await Storage.removeLogin();
    // IndexedDB.unmountDB();
    this.$router.push({
      name: "Login",
    });
  }
  navigable(name: string): boolean {
    return AuthModule.getUserRoles
      .flatMap((role) => {
        return Navigation.allowedRoutes[role.roleId];
      })
      .includes(name);
  }
}
