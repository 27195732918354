import { axiosInstance } from "@/plugins/axios";
import logger from "@/plugins/logger";
import { Shift, AttendanceViewModel } from "models";

const Shifts = () => {
  const baseUrl = "/api/shiftsAPI";

  const getShifts = async (
    fromDate: string,
    toDate: string,
    aroIDs: string[]
  ): Promise<Shift[]> => {
    const res = await axiosInstance.post(baseUrl + "/getAllShifts", {
      startDate: fromDate,
      endDate: toDate,
      aroIDs,
    });
    logger.log(res.data);
    return Promise.resolve(res.data);
  };
  const getAllShifts = async (): Promise<Shift[]> => {
    const res = await axiosInstance.get(baseUrl + "/getShifts");
    logger.log(res.data);
    return Promise.resolve(res.data);
  };

  const getHistoricShifts = async (
    fromDate: string,
    toDate: string
  ): Promise<Shift[]> => {
    const res = await axiosInstance.get(`${baseUrl}/getShiftsHistoric`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
      },
    });
    logger.log(res.data);
    return Promise.resolve(res.data);
  };

  const addShift = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/addShifts", data);
    return Promise.resolve(res.data);
  };

  const editShift = async (data: FormData): Promise<void> => {
    const res = await axiosInstance.post(baseUrl + "/editShift", data);
    return Promise.resolve(res.data);
  };

  const deleteShift = async (id: number): Promise<void> => {
    const res = await axiosInstance.delete(
      baseUrl + `/deleteShift?shiftID=${id}`
    );
    return Promise.resolve(res.data);
  };

  const addShiftSite = async (name: string, isArea: boolean): Promise<void> => {
    const res = await axiosInstance.post(
      baseUrl + `/addNewShiftSite?siteName=${name}&isArea=${isArea}`
    );
    return Promise.resolve(res.data);
  };

  const GetShiftAttendance = async (id: number): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/shiftAttendanceApi?shiftID=${id}`
    );
    return Promise.resolve(res.data);
  };

  const ShiftAttendance = async (
    id: number,
    attendanceViews: AttendanceViewModel,
    isteam: boolean
  ): Promise<any> => {
    const res = await axiosInstance.post(
      baseUrl +
        `/shiftAttendance?shiftID=${id}&attendanceViews=${attendanceViews}&isteam=${isteam}`
    );
    return Promise.resolve(res.data);
  };

  return {
    getShifts,
    getAllShifts,
    getHistoricShifts,
    addShift,
    editShift,
    addShiftSite,
    deleteShift,
    GetShiftAttendance,
    ShiftAttendance,
  };
};
const temp = Shifts();
export default temp;
