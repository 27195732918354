
import { Component, Vue, PropSync, Watch, Prop } from "vue-property-decorator";
import { Shifts } from "@/hooks";
import { Shift, AttendanceViewModel } from "models";
import logger from "@/plugins/logger";

@Component({})
export default class ShiftAttendance extends Vue {
  @PropSync("open") attendanceModal!: boolean;
  @Prop() readonly data!: any;
  valid = false;
  teamMembers: any[] = [];
  standByMembers: any[] = [];
  attendance: any[] = [];
  selected = [];
  teamHeaders = [
    { text: "Team Members", value: "armResponseOfficer.name", sortable: false },
    { text: "Joining Date", value: "joiningDate", sortable: false },
    { text: "Joining Time", value: "joiningTime", sortable: false },
    { text: "Leaving Date", value: "leavingDate", sortable: false },
    { text: "Leaving Time", value: "leavingTime", sortable: false },
  ];
  standByHeaders = [
    {
      text: "Standby Shift Members",
      value: "armResponseOfficer.name",
      sortable: false,
    },
    { text: "Joining Date", value: "joiningDate", sortable: false },
    { text: "Joining Time", value: "joiningTime", sortable: false },
    { text: "Leaving Date", value: "leavingDate", sortable: false },
    { text: "Leaving Time", value: "leavingTime", sortable: false },
  ];

  @Watch("data", { immediate: false })
  async modalDataChanged(): Promise<void> {
    logger.log("Modal Data", this.data);
    await this.getShiftAttendance(this.data.id);

    this.teamMembers = [];
    this.data.shiftArmResponseOfficers.forEach((elem: any) => {
      if (elem.isTeamMember == true) {
        this.teamMembers.push(elem);
      } else {
        this.standByMembers.push(elem);
      }
    });

    this.teamMembers.forEach((elem: any) => {
      elem.joiningDateMenu;
      elem.joiningDate;
      elem.joiningTimeMenu;
      elem.joiningTime;
      elem.leavingDateMenu;
      elem.leavingDate;
      elem.leavingTimeMenu;
      elem.leavingTime;
    });

    this.standByMembers.forEach((elem: any) => {
      elem.joiningDateMenu;
      elem.joiningDate;
      elem.joiningTimeMenu;
      elem.joiningTime;
      elem.leavingDateMenu;
      elem.leavingDate;
      elem.leavingTimeMenu;
      elem.leavingTime;
    });

    if (this.attendance != [] || this.attendance != null) {
      this.teamMembers.forEach((elem) => {
        this.attendance.forEach((item) => {
          if (elem.armResponseOfficerID == item.armResponseOfficerID) {
            elem.joiningDate = item.joiningDateStr;
            elem.joiningTime = item.joiningTime;
            elem.leavingDate = item.leavingDateStr;
            elem.leavingTime = item.leavingTime;
          }
        });
      });
      this.standByMembers.forEach((elem) => {
        this.attendance.forEach((item) => {
          if (elem.armResponseOfficerID == item.armResponseOfficerID) {
            elem.joiningDate = item.joiningDateStr;
            elem.joiningTime = item.joiningTime;
            elem.leavingDate = item.leavingDateStr;
            elem.leavingTime = item.leavingTime;
          }
        });
      });
    }

    logger.log("teamMembers", this.teamMembers);
    logger.log("standByMembers", this.standByMembers);
  }

  @Watch("selected", { immediate: false })
  selectedChanged(): void {
    logger.log("Selected", this.selected);
  }

  async getShiftAttendance(id: number): Promise<void> {
    try {
      const res = await Shifts.GetShiftAttendance(id);
      this.attendance = res;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  closeModal(): void {
    this.teamMembers.forEach((elem: any) => {
      elem.joiningDateMenu = false;
      elem.joiningDate = "";
      elem.joiningTimeMenu = false;
      elem.joiningTime = "";
      elem.leavingDateMenu = false;
      elem.leavingDate = "";
      elem.leavingTimeMenu = false;
      elem.leavingTime = "";
    });

    this.standByMembers.forEach((elem: any) => {
      elem.joiningDateMenu = false;
      elem.joiningDate = "";
      elem.joiningTimeMenu = false;
      elem.joiningTime = "";
      elem.leavingDateMenu = false;
      elem.leavingDate = "";
      elem.leavingTimeMenu = false;
      elem.leavingTime = "";
    });

    this.attendanceModal = false;
  }
}
