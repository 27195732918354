
import { Component, Vue, PropSync } from "vue-property-decorator";
import { Ammo } from "@/hooks";
import { Ammunition } from "models";
import logger from "@/plugins/logger";

@Component({})
export default class manageItemModal extends Vue {
  @PropSync("open") manageItem!: boolean;
  @PropSync("refresh") refreshData!: boolean;
  valid = false;
  purchaseDateMenu = false;
  data: { [index: string]: any } = {
    batchNumber: "",
    bookedOut: "",
    calibre: "",
    make: "",
    purchaseDate: "",
    purchasedFrom: "",
    quantity: "",
  };
  proofOfPurchase = "";
  calibres = [
    ".22LR",
    "6,35 Auto",
    "7,65 Auto",
    ".38 Special",
    ".357 Magnum",
    "9mm S",
    "9mm P",
    ".40S&W",
    ".45ACP",
    ".12 Gauge",
    ".12 Gauge - Less Lethal",
    ".22LR",
    "5,56X45",
    ".223 REM",
    "7,62X39",
    "7,62X51",
    ".308 WIN",
  ];
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",
  };

  closeModal(): void {
    this.proofOfPurchase = "";
    this.manageItem = false;
  }

  async addItem(): Promise<void> {
    logger.log("Add user info: ", this.data);

    var formdata = new FormData();
    Object.keys(this.data).forEach((elem) => {
      /* logger.log(elem, this.data[elem]); */
      formdata.append(elem, this.data[elem]);
    });

    formdata.append("ammo", this.proofOfPurchase);

    try {
      const res = await Ammo.addAmmunition(formdata);
      this.$notifCreator.createSuccessNotification(
        "Ammunition Added Succesfully."
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.closeModal();
    }
  }
}
