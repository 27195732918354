
import { Component, Vue } from "vue-property-decorator";
import { Navigation } from "@/hooks";
import AuthModule from "@/store/modules/Auth";

interface link {
  color: string;
  icon: string;
  title: string;
  path?: string;
}
@Component
export default class QuickLinks extends Vue {
  hasAccess(name: string | undefined): boolean {
    if (name != "dash.manage.incidents") {
      const has = Navigation.hasAccess(name);
      // logger.log("Name: ", name, has);
      return has;
    } else {
      return AuthModule.getIncidentProxy;
    }
  }
  get getLinks(): link[] {
    return this.links.filter((e) => this.hasAccess(e.path) == true);
  }
  goTo(name: string | undefined): void {
    if (name != undefined) {
      this.$router.push({
        name: name,
      });
    }
  }
  links: link[] = [
    {
      color: "#1660A8",
      icon: "mdi-account-multiple",
      title: "Manage Administrators",
      path: "dash.manage.users",
    },
    {
      color: "#C1392B",
      icon: "mdi-account-multiple",
      title: "Manage ARO's",
      path: "dash.manage.aro",
    },
    {
      color: "#1660A8",
      icon: "mdi-note-edit",
      title: "Manage Documents",
      path: "dash.manage.documents",
    },
    {
      color: "#103961",
      icon: "mdi-account-group",
      title: "Manage Teams",
      path: "dash.manage.teams",
    },
    {
      color: "#851C11",
      icon: "mdi-badge-account",
      title: "Manage Shifts",
      path: "dash.manage.shifts",
    },
    {
      color: "#C1392B",
      icon: "mdi-alert",
      title: "Manage Incidents",
      path: "dash.manage.incidents",
    },
    {
      color: "#103961",
      icon: "mdi-archive",
      title: "Permits",
      path: "dash.manage.permits",
    },
    {
      color: "#103961",
      icon: "mdi-check-decagram",
      title: "My Permits",
      path: "dash.myPermits",
    },
    {
      color: "#103961",
      icon: "mdi-check-decagram-outline",
      title: "Offline Permits",
      path: "dash.offlinePermits",
    },
    {
      color: "#232C35",
      icon: "mdi-target",
      title: "Request Firearm Permits",
    },
    // {
    //   color: "#979797",
    //   icon: "mdi-clipboard-arrow-left",
    //   title: "Return Firearm Permits",
    //   path: "dash.myPermits",
    // },
    {
      color: "#851C11",
      icon: "mdi-pistol",
      title: "Manage Firearms",
      path: "dash.registers.firearms",
    },
    {
      color: "#1660A8",
      icon: "mdi-bullet",
      title: "Manage Ammunition",
      path: "dash.registers.ammo",
    },
    {
      color: "#232C35",
      icon: "mdi-file-certificate",
      title: "Issue Permits",
    },
    {
      color: "#979797",
      icon: "mdi-calendar-clock",
      title: "Pending Permit Requests",
    },
    {
      color: "#103961",
      icon: "mdi-account-multiple",
      title: "Daily Combined",
      path: "dash.registers.dailyCombined",
    },
    {
      color: "#1660A8",
      icon: "mdi-book",
      title: "Pocketbook",
      path: "dash.pocketbook.aro",
    },
    {
      color: "#1660A8",
      icon: "mdi-book",
      title: "Pocketbook",
      path: "dash.pocketbook.supervisor",
    },
  ];
}
