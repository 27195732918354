import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import store from "@/store";
import { notificationType } from "models";
import { NOTIFICATION } from "@/enums/notifications";
import { DeviceInfo } from "@capacitor/device";
import logger from "@/plugins/logger";
import { axiosInstance } from "@/plugins/axios";

interface ApiNotification {
  body: string;
  title?: string;
  id: number;
  time: string;
  date: string;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "NotificationSystem",
  store,
})
class NotificationSystem extends VuexModule {
  deviceInfo: DeviceInfo | null = null;
  currentNotif: notificationType | null = null;
  firebaseToken: string | null = null;
  notifQueue: notificationType[] = [];
  apiNotitications: ApiNotification[] = [];
  unreadNotitications: ApiNotification[] = [];

  get getCurrentNotif(): notificationType | null {
    logger.log("Current Notification");
    return this.currentNotif;
  }
  get getNotifQueue(): notificationType[] {
    return this.notifQueue;
  }
  get getDeviceInfo(): DeviceInfo | null {
    return this.deviceInfo;
  }
  @Mutation
  setDeviceInfo(token: DeviceInfo | null) {
    this.deviceInfo = token;
  }
  @Action
  addNotifAndTimeoutCheck(notification: notificationType): void {
    if (this.currentNotif != null) {
      this.addToQueue(notification);
    } else {
      this.setCurrentNotif(notification);
      if (notification.timeout != -1) {
        //   logger.log("Not -1 ", notification)
        window.setTimeout(
          this.clearCurrentNotifWithCheck,
          notification.timeout + 3000,
          notification
        );
      }
    }
  }
  @Mutation
  addToQueue(notification: notificationType) {
    this.notifQueue.push(notification);
  }
  @Mutation
  addToUnreadNotifications(notification: ApiNotification) {
    this.unreadNotitications.push(notification);
  }
  @Mutation
  clearUnreadNotifications() {
    this.unreadNotitications.splice(0);
  }
  @Mutation
  removeFromQueue(notification: notificationType) {
    this.notifQueue = this.notifQueue.filter((e) => e !== notification);
  }
  @Mutation
  setCurrentNotif(notification: notificationType | null) {
    this.currentNotif = notification;
  }
  @Mutation
  setNotificationsFromApi(notifications: any[]) {
    this.apiNotitications = notifications;
  }
  // @Mutation
  // addNotification(notification: notificationType): void {
  //   if (this.currentNotif == null) {
  //     this.currentNotif = notification;
  //   } else {
  //     this.notifQueue.push(notification);
  //   }
  // }
  @Action
  clearCurrentNotifWithCheck(notif: notificationType): void {
    if (this.currentNotif == notif) {
      logger.log("Calling clear with check", notif);
      if (this.notifQueue.length > 0) {
        const nextNotif = this.notifQueue[0];
        this.removeFromQueue(nextNotif);
        this.setCurrentNotif(nextNotif);
        if (nextNotif.timeout != -1)
          window.setTimeout(
            this.clearCurrentNotifWithCheck,
            nextNotif.timeout + 3000,
            nextNotif
          );
      } else {
        logger.log("notifqueue == 0");
        this.setCurrentNotif(null);
      }
    }
  }
  @Action
  clearCurrentNotif(): void {
    if (this.notifQueue.length > 0) {
      const nextNotif = this.notifQueue[0];
      this.removeFromQueue(nextNotif);
      this.setCurrentNotif(nextNotif);
    } else {
      this.setCurrentNotif(null);
    }
  }
  get getFirebaseToken(): string | null {
    return this.firebaseToken;
  }
  @Mutation
  setFirebaseToken(token: string | null) {
    this.firebaseToken = token;
  }

  @Action
  async getNotificationFromApi() {
    const res = await axiosInstance.get(
      "/api/Notification/GetUserNotification"
    );
    debugger;
    this.setNotificationsFromApi(res.data);
    return Promise.resolve();
  }
}
export default getModule(NotificationSystem);
