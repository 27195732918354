
import { Component, Vue, Watch } from "vue-property-decorator";
import { Downloader, Reports } from "@/hooks";
import { ShiftReportViewModel } from "models";
import JsonExcel from "@/components/JsonExcel.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";

@Component({
  components: {
    JsonExcel,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class Incident_Report extends Vue {
  reports: ShiftReportViewModel[] = [];
  reportsFiltered: ShiftReportViewModel[] = [];
  loading = false;
  search = "";
  sortDesc = true;
  sortBy = "startDate";
  expanded: ShiftReportViewModel[] = [];
  headers = [
    { text: "Site/ Area Name", value: "shiftSiteName" },
    { text: "Start Date", value: "startDate" },
    { text: "End Date", value: "endDate" },
    { text: "Booking on Time", value: "bookingOnTime" },
    { text: "Booking off Time", value: "bookingOffTime" },
    { text: "", value: "data-table-expand" },
  ];
  expReports: {
    "Site/Area Name": string;
    "Start Date": string;
    "End Date": string;
    "Booking on Time": string;
    "Booking off Time": string;
    Team: string;
    "Team Type": string;
    "Team Members": any;
    "Standby Shift Members": any;
  }[] = [];
  expFields = {
    "Site/ Area Name": "shiftSiteName",
    "Start Date": "startDate",
    "End Date": "endDate",
    "Booking on Time": "bookingOnTime",
    "Booking off Time": "bookingOffTime",
    Team: "teamName",
    "Team Type": "isSecurityGuardTeam",
    "Team Members": "teamMembers",
    "Standby Shift Members": "standbyMemebers",
  };

  /* @Watch("expanded", { immediate: true })
  tableHedersChanged(): void {
    logger.log("expanded", this.expanded);
  } */

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const res = await Reports.getShiftReport();
      this.reports = res;
      this.reports.forEach((item, i) => {
        item.id = i + 1;
      });
      this.expReports = this.populateExportData(this.reports);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  replace(i: string): string {
    return i.replace(/;/g, "");
  }
  updateReportsFiltered(event: any) {
    this.reportsFiltered = event ? (event as any[]) : this.reports;
  }
  get getReportsFiltered() {
    return this.populateExportData(this.reportsFiltered);
  }
  async generatePDF(): Promise<void> {
    try {
      // this.loading.pdf = true;
      const columns = [
        { header: "Site/Area Name", dataKey: "Site/Area Name" },
        { header: "Start Date", dataKey: "Start Date" },
        { header: "End Date", dataKey: "End Date" },
        { header: "Booking on Time", dataKey: "Booking on Time" },
        { header: "Booking off Time", dataKey: "Booking off Time" },
        { header: "Team", dataKey: "Team" },
        { header: "Team Type", dataKey: "Team Type" },
        { header: "Team Members", dataKey: "Team Members" },
        { header: "Standby Shift Memberst", dataKey: "Standby Shift Members" },
      ];
      await Downloader.generatePdfAndSave({
        fileName: `Shift Report (${dayjs().format("YYYY-MM-DD")})`,
        columns: columns,
        body: this.expReports,
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  populateExportData(items: any[]) {
    return items.map((elem) => {
      return {
        "Site/Area Name": elem.shiftSiteName != null ? elem.shiftSiteName : " ",
        "Start Date":
          elem.startDate != null
            ? dayjs(elem.startDate).format("YYYY-MM-DD")
            : " ",
        "End Date":
          elem.endDate != null ? dayjs(elem.endDate).format("YYYY-MM-DD") : " ",
        "Booking on Time":
          elem.bookingOnTime != null ? elem.bookingOnTime : " ",
        "Booking off Time":
          elem.bookingOffTime != null ? elem.bookingOffTime : " ",
        Team: elem.teamName != null ? elem.teamName : " ",
        "Team Type": elem.isSecurityGuardTeam == true ? "SO Team" : "ARO Team",
        "Team Members": elem.teamMembers != null ? elem.teamMembers : " ",
        "Standby Shift Members":
          elem.standbyMemebers != null ? elem.standbyMemebers : " ",
      };
    });
  }
}
