
import dayjs from "dayjs";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Level } from "@/enums/level";
import { Documents } from "@/hooks";
import Docs from "@/store/modules/Documents";
import { DOCUMENTS } from "@/enums/documents";
import { AROViewModel } from "models";
import logger from "@/plugins/logger";

@Component({
  filters: {
    pretty: function (value: any) {
      logger.log("Pretty");
      return JSON.parse(JSON.stringify(value, null, 4));
    },
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    convertGrade: function (value: string) {
      const conv = Number(value).valueOf();
      switch (conv) {
        case Level.Basic:
          return "Basic";
        case Level.Advanced:
          return "Advanced";
        case Level.Intermediate:
          return "Intermediate";
      }
    },
    daysRemaining: function (value: string) {
      const today = dayjs();
      const exp = dayjs(value);
      const rem = today.diff(exp, "d");

      if (rem < 0) {
        return -1 * rem + " days remaining";
      } else {
        return rem + " days late";
      }
    },
    outstandingTitles: function (value: string) {
      // logger.log("Value ", value);
      switch (value) {
        case "tacticalKnowledge":
          return "Tactical Knowledge";
        case "koa":
          return "Knowledge of Act";
        case "basicPus":
          return "Basic Proficiency";
        case "businessPus":
          return "Business Proficiency";
        case "psiraRegDocs":
          return "PSIRA Registration";
        case "sapsComp":
          return "SAPS Competency";
        case "reg21":
          return "Regulation 21";
        default:
          return "Missing Def - " + value;
      }
    },
  },
})
export default class OverviewDocs extends Vue {
  dayjs = dayjs;

  viewStep(val: string) {
    // logger.log("View from overview ", val);
    this.$emit("viewStep", val);
  }
  get selectedUser() {
    return Docs.getAro;
  }
  set selectedUser(val: AROViewModel | null) {
    Docs.setAro(val);
  }
  get getAroDocs() {
    return Docs.getAroDocs;
  }
  get getAroDocsBreakdown() {
    return Docs.getAroDocsBreakdown;
  }
  get getUrgentCount(): number {
    let count = 0;
    if (this.getAroDocs != null) {
      Object.entries(this.getAroDocsBreakdown.urgent).forEach((key) => {
        // logger.log("Urgent counter ", key);
        count += key[1].length;
      });
    }
    return count;
  }
  get getCompletedCount(): number {
    let count = 0;
    if (this.getAroDocs != null) {
      Object.entries(this.getAroDocsBreakdown.completed).forEach(
        ([key, value]) => {
          // logger.log("Completed counter ", key);
          count += value;
        }
      );
    }
    return count;
  }
  get getOutstandingCount(): number {
    let count = 0;
    if (this.getAroDocs != null) {
      Object.entries(this.getAroDocsBreakdown.outstanding).forEach((key) => {
        // logger.log("Outstanding counter ", key);
        if (key[1] == true) {
          count += 1;
        }
      });
    }
    return count;
  }
  get getRenewedCount(): number {
    let count = 0;
    if (this.getAroDocs != null) {
      Object.entries(this.getAroDocsBreakdown.isRenewed).forEach((key) => {
        // logger.log("Renewed counter ", key);
        count += key[1].length;
      });
    }
    return count;
  }
}
