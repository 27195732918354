
// import { writeFile } from "fs/promises";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Documents, Downloader, Permits, ShiftSite } from "@/hooks";
import { axiosInstance } from "@/plugins/axios";
import dayjs from "dayjs";
import { DataTableHeader } from "vuetify";
import {
  ManageUser,
  ManageUserProper,
  ShiftSite as ShiftSiteModel,
} from "models";
import JsonExcel from "@/components/JsonExcel.vue";
import logger from "@/plugins/logger";

interface CustomType {
  userName: "Xavian";
  userSignatureData: null;
  userSignaturePath: "Upload\\Signature\\105ac55csignature.png";
  userSurname: "Smit";
  usesDatabase: false;
}
interface dailyHeaderTable {
  site: string | null;
  sapRef: string | null;
  caseRef: string | null;
  reportRef: string | null;
  obRef: string | null;
  ownerOfregister: string;
}

interface dailyResponse extends dailyHeaderTable {
  manageUser: CustomType;
  manageUserSignature: string | null;
  manageUserSignatureData: ArrayBuffer | null;
  manageUserUsesDatabase: boolean;
  pageNo: null;
  dailyFirearmAndAmmoViewModel: DailyCombinedData[];
  webRoot: string;
}
interface DailyCombinedData {
  ammunitionCount: number | null;
  calibre: string | null;
  checkedBy: string | null;
  createByUser: CustomType;
  date: string;
  issuedToUser: CustomType;
  make: string;
  permitNumber: number;
  receivedByUser: CustomType;
  returnedByUser: CustomType;
  returnedDate: null | string;
  serialNumber: string;
}

interface DailyCombinedData2 {
  date: string | null;
  make: string | null;
  serialNumber: string | null;
  calibre: string | null;
  ammunitionCount: number | null;
  issuedToName: string | null;
  issuedToSurname: string | null;
  issuedToSignaturePath: string | null;
  issuedToSignatureData: null;
  issuedToUsesDatabase: boolean;
  createdByName: string | null;
  createdBySurname: string | null;
  createdBySignaturePath: string | null;
  createdBySignatureData: null;
  createdByUsesDatabase: boolean;
  returnedByName: string | null;
  returnedBySurname: string | null;
  returnedBySignaturePath: string | null;
  returnedBySignatureData: null;
  returnedByUsesDatabase: boolean;
  receivedByName: string | null;
  receivedBySurname: string | null;
  receivedBySignaturePath: string | null;
  receivedBySignatureData: null;
  receivedByUsesDatabase: false;
  returnedDate: string | null;
  checkedBy: string | null;
  permitNumber: number;
}

@Component({
  components: {
    JsonExcel,
  },
})
export default class DailyCombined extends Vue {
  loading = false;
  response: dailyResponse | null = null;
  selectedShiftSite: null | string = null;
  shiftSites: ShiftSiteModel[] = [];
  // items: DailyCombinedData[] = [];
  expFieldsHeaders = {
    "Owner of Register": "ownerOfregister",
    "Supervising and/or issuing officer name:": "issuingOfficerName",
    "SAP Ref": "sapRef",
    "Case Ref": "caseRef",
    "Report Ref": "reportRef",
    "OB Ref": "obRef",
    Site: "site",
  };
  expFields = {
    "Ammunition Count": "ammunitionCount",
    Calibre: "calibre",
    "Checked By": "checkedBy",
    "Created By": "createByUser",
    Date: "date",
    "Issued To": "issuedToUser",
    Make: "make",
    "Permit Number": "permitNumber",
    "Received By": "receivedByUser",
    "Returned By": "returnedByUser",
    "Return Date": "returnedDate",
    "S/N": "serialNumber",
  };
  expReports: any[] = [];
  expReportsHeaders: any[] = [];
  headers: DataTableHeader[] = [
    {
      text: "Date",
      value: "date",
    },
    {
      text: "Make",
      value: "make",
    },
    {
      text: "S/N",
      value: "serialNumber",
    },
    {
      text: "Calibre",
      value: "calibre",
    },
    {
      text: "Issued To",
      value: "issuedTo",
    },
    {
      text: "Ammunition Count",
      value: "ammunitionCount",
    },
    {
      text: "Site",
      value: "siteName",
    },
    {
      text: "Created By",
      value: "createdBy",
    },
    {
      text: "Returned By",
      value: "returnedBy",
    },
    {
      text: "Received By",
      value: "receivedBy",
    },

    {
      text: "Checked By",
      value: "permitCheckedByUser",
    },
    {
      text: "Cancelled Reason",
      value: "cancelledReason",
    },
    {
      text: "Permit Number",
      value: "permitNumber",
    },
  ];
  dateFilters = {
    to: {
      active: false,
      date: dayjs().format("YYYY-MM-DD"),
    },
    from: {
      active: false,
      date: dayjs().subtract(4, "days").format("YYYY-MM-DD"),
    },
  };

  get dateCheck(): boolean {
    const diff = dayjs(this.dateFilters.to.date).diff(
      dayjs(this.dateFilters.from.date),
      "days"
    );
    //logger.log("diff", diff);
    if (diff > 90) {
      return true;
    } else {
      return false;
    }
  }

  async mounted() {
    await this.loadShiftSites();
    await this.loadDailyCombined();
  }
  async loadShiftSites() {
    try {
      this.shiftSites = await ShiftSite.getShiftSites();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async downloadPDF() {
    this.loading = true;
    try {
      //debugger;
      const res = await axiosInstance.get(
        "/api/CombinedRegister/CombinedRegisterPDFDownload",
        {
          params: {
            fromDate: this.dateFilters.from.date,
            toDate: this.dateFilters.to.date,
            shiftSite: this.selectedShiftSite
              ? this.selectedShiftSite
              : undefined,
          },
          responseType: "blob",
        }
      );
      const base = await Documents.getBase64(res.data);
      if (typeof base === "string") {
        Downloader.saveFile(
          base,
          `DailyCombinedRegister-${this.dateFilters.from.date}-${
            this.dateFilters.to.date
          }${this.selectedShiftSite ? this.selectedShiftSite : ""}`,
          "application/pdf"
        );
      }
      logger.log("type of ", typeof res.data, res.data);

      // const pdfContents = res.data;
      // let blob = new Blob([res.data], { type: "application/pdf" });
      // let link = document.createElement("a");
      // link.href = window.URL.createObjectURL(blob);
      // link.download = "Results.pdf";
      // link.click();
      // await writeFile("file.pdf", pdfContents);
      logger.log("This is the response: ", res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  get items() {
    if (this.response == null) return [];
    return this.response.dailyFirearmAndAmmoViewModel;
  }
  async loadDailyCombined() {
    try {
      this.loading = true;
      this.items.splice(0);
      const fromDate = dayjs().subtract(1, "days");
      //debugger;
      const res = await axiosInstance.get(
        "/api/CombinedRegister/getCombinedRegister",
        {
          params: {
            fromDate: this.dateFilters.from.date,
            toDate: this.dateFilters.to.date,
            shiftSite: this.selectedShiftSite
              ? this.selectedShiftSite
              : undefined,
          },
        }
      );
      // logger.log(temp);
      // this.items = [res.data];
      this.response = res.data;
      logger.log(this.response);
      //debugger;
      if (this.response) {
        this.expReports = this.populateExportData(
          this.response.dailyFirearmAndAmmoViewModel
        );
        this.expReportsHeaders = this.populateExportDataHeaders(this.response);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  populateExportDataHeaders(res: dailyResponse) {
    //order matters
    return [
      {
        ownerOfregister: res.ownerOfregister ? res.ownerOfregister : "",
        issuingOfficerName:
          res.manageUser.userName + " " + res.manageUser.userSurname,
        sapRef: res.sapRef ? res.sapRef : "",
        caseRef: res.caseRef ? res.caseRef : "",
        reportRef: res.reportRef ? res.reportRef : "",
        obRef: res.obRef ? res.obRef : "",
        site: res.site ? res.site : "",
      } as dailyHeaderTable,
    ];
  }
  combineNameSurname(name: string | null, surname: string | null) {
    let temp = "";
    if (name != null) {
      temp = name + " ";
    }
    if (surname != null) {
      temp += surname;
    }
    return temp;
  }
  populateExportData(items: DailyCombinedData[]) {
    return items.map((elem: DailyCombinedData) => {
      return {
        ammunitionCount: elem.ammunitionCount,
        calibre: elem.calibre,
        checkedBy: elem.checkedBy,
        createByUser: this.combineNameSurname(
          elem.createByUser.userName,
          elem.createByUser.userSurname
        ),
        date: elem.date,
        issuedToUser: this.combineNameSurname(
          elem.issuedToUser.userName,
          elem.issuedToUser.userSurname
        ),
        make: elem.make,
        permitNumber: elem.permitNumber,
        receivedByUser: this.combineNameSurname(
          elem.receivedByUser.userName,
          elem.receivedByUser.userSurname
        ),
        returnedByUser: this.combineNameSurname(
          elem.returnedByUser.userName,
          elem.returnedByUser.userSurname
        ),
        returnedDate: elem.returnedDate,
        serialNumber: elem.serialNumber,
      };
    });
  }
}
