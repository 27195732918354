
import VuePdfApp from "vue-pdf-app";
import { Component, Vue, Watch } from "vue-property-decorator";
import PsiraReg from "@/views/manage/documents/steps/PsiraReg.vue";
import KnowledgeOfAct from "@/views/manage/documents/steps/KnowledgeOfAct.vue";
import BasicPus from "@/views/manage/documents/steps/BasicPus.vue";
import SapsCompentency from "@/views/manage/documents/steps/SapsCompentency.vue";
import BusinessPus from "@/views/manage/documents/steps/BusinessPus.vue";
import Regulation21 from "@/views/manage/documents/steps/Regulation21.vue";
import RangeOfficerUnitStandards from "@/views/manage/documents/steps/RangeOfficerUnitStandards.vue";
import TacticalKnowledge from "@/views/manage/documents/steps/TacticalKnowledge.vue";
import TacticalUnitStandards from "@/views/manage/documents/steps/TacticalUnitStandards.vue";
import FirearmInstructorUnitStandards from "@/views/manage/documents/steps/FirearmInstructorUnitStandards.vue";
import BasicPersonalDocs from "@/views/manage/documents/steps/BasicPersonalDocs.vue";
import ComplexDataIterator from "@/views/manage/documents/ComplexDataIterator.vue";
import Docs from "@/store/modules/Documents";
import { Certificate } from "models";
import { axiosInstance } from "@/plugins/axios";
import { Documents } from "@/hooks";
import logger from "@/plugins/logger";

@Component({
  components: {
    VuePdfApp,
    PsiraReg,
    KnowledgeOfAct,
    BasicPus,
    SapsCompentency,
    BusinessPus,
    Regulation21,
    RangeOfficerUnitStandards,
    TacticalKnowledge,
    TacticalUnitStandards,
    FirearmInstructorUnitStandards,
    BasicPersonalDocs,
    ComplexDataIterator,
  },
})
export default class ModifyUserDocs extends Vue {
  edit: {
    view: boolean;
    item: Certificate | null;
  } = {
    view: false,
    item: null,
  };

  triggerEdit(item: any | null, val: boolean): void {
    if (item != null && item == this.edit.item) {
      Vue.set(this.edit, "view", val);
    } else if (item != this.edit.item) {
      Vue.set(this.edit, "view", !val);
      setTimeout(() => {
        Vue.set(this.edit, "item", item);
        Vue.set(this.edit, "view", val);
      }, 200);
    } else {
      Vue.set(this.edit, "view", val);
    }
    // if (this.edit.view == true && this.edit.item != null)
    //   Vue.set(this.edit, "view", !this.edit.view);
    // setTimeout(() => {
    //   Vue.set(this.edit, "item", item);
    //   Vue.set(this.edit, "view", !this.edit.view);
    // }, 200);

    // if (item != null) {
    //   if (this.edit.item == item) {
    //     if (this.edit.view == true) {
    //       Vue.set(this.edit, "view", false);
    //       setTimeout(() => Vue.set(this.edit, "item", null), 200);
    //     } else {
    //       Vue.set(this.edit, "item", item);
    //       Vue.set(this.edit, "view", true);
    //     }
    //   } else {
    //     logger.log("Diff item to edit");
    //     if (this.edit.view == true && this.edit.item != null)
    //       Vue.set(this.edit, "view", !this.edit.view);
    //     setTimeout(() => {
    //       Vue.set(this.edit, "item", item);
    //       Vue.set(this.edit, "view", !this.edit.view);
    //     }, 200);
    //   }
    // } else {
    //   if (this.edit.view == true && this.edit.item != null)
    //     Vue.set(this.edit, "view", !this.edit.view);
    //   setTimeout(() => {
    //     Vue.set(this.edit, "item", null);
    //     Vue.set(this.edit, "view", !this.edit.view);
    //   }, 200);
    // }
  }

  async deleteFile(): Promise<void> {
    logger.log("Step", this.currentStep);
    try {
      var fileId = 0;

      if (this.getAroDetails) {
        switch (this.currentStep) {
          case 1:
            for (const doc of this.getAroDetails.psiraRegistrationDocuments) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res = await Documents.deleteAllPSIRARegistration(fileId);
              Promise.resolve(res);
            }
            break;
          case 2:
            for (const doc of this.getAroDetails.basicFirearmProficiencies) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res2 = await Documents.deleteAllBasicFirearmProficiency(
                fileId
              );
              Promise.resolve(res2);
            }
            break;
          case 3:
            var filtertedBasicProf =
              this.getAroDetails.basicProficiencyCertificates.filter(
                (e) => e.isTacticalUnitStandards == false
              );
            for (const doc of filtertedBasicProf) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res3 = await Documents.deleteAllBasicProficiency(fileId);
              Promise.resolve(res3);
            }
            break;
          case 4:
            var filtertedSAPS =
              this.getAroDetails.sapsCompetencyCertificates.filter(
                (e) => e.isRegulation21Certificate == false
              );
            for (const doc of filtertedSAPS) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res4 = await Documents.deleteAllSAPSCompetency(fileId);
              Promise.resolve(res4);
            }
            break;
          case 5:
            for (const doc of this.getAroDetails
              .businessProficiencyCertificates) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res5 = await Documents.deleteAllBusinessProficiency(fileId);
              Promise.resolve(res5);
            }
            break;
          case 6:
            var filtertedReg21 =
              this.getAroDetails.sapsCompetencyCertificates.filter(
                (e) => e.isRegulation21Certificate == true
              );
            for (const doc of filtertedReg21) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res6 = await Documents.deleteAllSAPSCompetency(fileId);
              Promise.resolve(res6);
            }
            break;
          case 7:
            for (const doc of this.getAroDetails.adhocTrainingDocuments) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res7 = await Documents.deleteAdhocTraining(fileId);
              Promise.resolve(res7);
            }

            for (const doc of this.getAroDetails.drivingLicenses) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var resDrivers = await Documents.deleteAllDrivingLicense(fileId);
              Promise.resolve(resDrivers);
            }

            for (const doc of this.getAroDetails.idDocuments) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var resId = await Documents.deleteIdDocument(fileId);
              Promise.resolve(resId);
            }
            break;
          case 8:
            for (const doc of this.getAroDetails.rangeOfficerUnitStandards) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res8 = await Documents.deleteAllRangeOfficer(fileId);
              Promise.resolve(res8);
            }
            break;
          case 9:
            for (const doc of this.getAroDetails.tacticalKnowledge) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res9 = await Documents.deleteAllTacticalKnowledge(fileId);
              Promise.resolve(res9);
            }
            break;
          case 10:
            var filtertedTactical =
              this.getAroDetails.basicProficiencyCertificates.filter(
                (e) => e.isTacticalUnitStandards == true
              );
            for (const doc of filtertedTactical) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res10 = await Documents.deleteAllBasicProficiency(fileId);
              Promise.resolve(res10);
            }
            break;
          case 11:
            for (const doc of this.getAroDetails.instructorUnitStandards) {
              fileId = doc.id;
              logger.log("FileId to be deleted: ", fileId);
              var res11 = await Documents.deleteAllInstructor(fileId);
              Promise.resolve(res11);
            }
            break;
        }
      }
      setTimeout(this.refreshDocs, 10);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  get getCompletedBreakdown() {
    return Docs.getAroDocsBreakdown;
  }
  get getKoaCompleted(): boolean {
    return this.getCompletedBreakdown.outstanding.koa == false;
  }
  get getReg21Completed(): boolean {
    return this.getCompletedBreakdown.outstanding.reg21 == false;
  }
  get getFirearmInstructorCompleted(): boolean {
    if (this.getAroDetails != null) {
      return this.getAroDetails.instructorUnitStandards.length != 0;
    }
    return false;
  }
  get getTacticalKnowledgeCompleted(): boolean {
    // return this.getCompletedBreakdown.outstanding.tacticalKnowledge == false;
    if (this.getAroDetails != null) {
      return this.getAroDetails.tacticalKnowledge.length != 0;
    }
    return false;
  }
  get getTacticalUSCompleted(): boolean {
    if (this.getAroDetails != null) {
      return (
        this.getAroDetails.basicProficiencyCertificates.filter(
          (e) => e.isTacticalUnitStandards == true
        ).length != 0
      );
    }
    return false;
  }
  get getRangeOfficerCompleted(): boolean {
    if (this.getAroDetails != null) {
      return this.getAroDetails.rangeOfficerUnitStandards.length != 0;
    }
    return false;
  }
  get getBasicPusCompleted(): boolean {
    return this.getCompletedBreakdown.outstanding.basicPus == false;
  }
  get getBusinessPusCompleted(): boolean {
    return this.getCompletedBreakdown.outstanding.businessPus == false;
  }
  get getSapsCompleted(): boolean {
    if (this.getAroDetails != null) {
      return (
        this.getAroDetails.sapsCompetencyCertificates.filter(
          (e) => e.isRegulation21Certificate == false
        ).length != 0
      );
    }
    return false;
  }
  get getPsiraCompleted(): boolean {
    return this.getCompletedBreakdown.outstanding.psiraRegDocs == false;
  }
  get getBasicPersonalDocsCompleted(): boolean {
    if (this.getAroDetails != null) {
      return (
        this.getAroDetails.adhocTrainingDocuments.length != 0 &&
        this.getAroDetails.idDocuments.length != 0 &&
        this.getAroDetails.drivingLicenses.length != 0
      );
    }

    return false;
  }
  get currentStep() {
    return Docs.getCurrentStep;
  }
  set currentStep(val: number) {
    Docs.setCurrentStep(val);
  }
  onClick(e: PointerEvent, step: number): void {
    // logger.log("Type of click event: ", e, typeof e);
    if (this.currentStep === step) {
      // logger.log("Step and currentStep match");
      e.stopPropagation();
      e.preventDefault();

      this.$nextTick(() => {
        this.currentStep = -1;
      });
    } else {
      // logger.log("Step and currentStep dont match", this.currentStep, step);
    }
  }
  get canRangeOfficer(): boolean {
    if (this.getAroDetails != null) {
      return (
        this.getAroDetails.businessProficiencyCertificates.find(
          (e) => e.firearmType == "Handgun"
        ) != null &&
        this.getAroDetails.businessProficiencyCertificates.find(
          (e) => e.firearmType == "Shotgun"
        ) != null &&
        this.getAroDetails.businessProficiencyCertificates.find(
          (e) => e.firearmType == "Manually-Operated Rifle"
        ) != null &&
        this.getAroDetails.businessProficiencyCertificates.find(
          (e) => e.firearmType == "Self-Loading Rifle"
        ) != null
      );
    } else return false;
  }
  get getAro() {
    return Docs.getAro;
  }
  get getAroDetails() {
    return Docs.getAroDocs;
  }
  refreshDocs() {
    this.$emit("refresh");
  }
  @Watch("currentStep", {
    immediate: true,
  })
  onStepChanged(val: number | null, oldVal: number | null) {
    // logger.log("Step Changed from  ", oldVal, " to ", val);
    this.goto("mod-step-" + val);
  }

  goto(refName: string) {
    setTimeout(() => {
      var element = this.$refs[refName] as any;
      if (element) {
        // logger.warn("Element: ", element);
        // var top = element.$el.offsetTop;
        // // logger.log("----- GOTO :", refName, top, element, this.$refs);

        // window.scrollTo(0, top);
        element.$el.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  }
}
