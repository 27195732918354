import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import logger from "@/plugins/logger";
import Auth from "@/store/modules/Auth";
import { LoginResponse, SecurityFirm, UserDetails, userRole } from "models";
import { UserOverview } from "userModels";

const Authenticate = () => {
  const login = async ({
    username,
    password,
    deviceId,
  }: {
    username: string;
    password: string;
    deviceId: string;
  }): Promise<UserDetails> => {
    try {
      const res = await axiosNoAuth.post<LoginResponse>(
        "/api/authenticate/login",
        {
          username: username,
          password: password,
          deviceId,
        }
        // {
        //   transformResponse: (r: {
        //     data: { expiration: string; token: string };
        //   }) => r.data,
        // }
      );
      // logger.log("Logged in: ", res.data);
      const temp: UserDetails = {
        username: username,
        expiration: res.data.expiration,
        accessToken: res.data.token,
        issuePermit: false,
        incidents: false,
        reports: false,
      };
      return temp;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const getRoles = async (): Promise<userRole[]> => {
    try {
      const res = await axiosInstance.get("/api/authenticate/GetUserRolesByID");
      // logger.log("Get Roles: ", res.data);
      Auth.setUserRoles(res.data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const resetPassword = async (
    email: string | null = null /* ,
    password: string | null = null,
    confirmPassword: string | null = null */
  ): Promise<any> => {
    try {
      /* &password=${password}&confirmPassword=${confirmPassword} */
      const res = await axiosNoAuth.post(
        `/api/authenticate/ResetPasswordApp?email=${email}`
      );
      // logger.log("Get Roles: ", res.data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const getUser = async (): Promise<UserOverview> => {
    try {
      const res = await axiosInstance.get("/api/authenticate/GetUser");
      logger.log("Get User: ", res.data);
      Auth.setFirearmProxy(res.data.assignFirearmProxy);
      Auth.setincidentProxy(res.data.incidentProxy);
      Auth.setReportProxy(res.data.reportingProxy);
      Auth.setUserOverview(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return {
    login,
    resetPassword,
    getRoles,
    getUser,
  };
};
const temp = Authenticate();
export default temp;
