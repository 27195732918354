
import { Component, Vue, Watch } from "vue-property-decorator";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import { Permits } from "@/hooks";
import logger from "@/plugins/logger";

@Component({
  components: {
    VuePdfApp,
  },
  filters: {},
})
export default class FirearmPermit extends Vue {
  permit: Blob | null = null;
  loading = false;

  config = {
    toolbar: false,
  };

  async mounted(): Promise<void> {
    this.retrievePermit();
  }

  @Watch("$route.params.id", { immediate: true }) // Check if this provides a null to function, may be add guards for null/undefined etc.
  async pageChange(
    newtarget: string | undefined,
    oldtarget: string | undefined // Optional
  ): Promise<void> {
    // Might Fire twice but ultimately it's better to watch the $route.params.id here, versus relying on mount.
    logger.log("PDF View", oldtarget, newtarget);
    if (newtarget) {
      return await this.retrievePermit();
    }
    return Promise.resolve();
  }

  async retrievePermit(): Promise<void> {
    // https://app.zeroeight.co.za/permits/firearm/assignment?id=ID
    // https://app.zeroeight.co.za/permits/firearm/assignment?id=1
    // https://app.zeroeight.co.za/permits/firearm/assignment?id=2
    try {
      this.loading = true;
      this.permit = await Permits.getFirearmPermitPDF(this.$route.params.id);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
