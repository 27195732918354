import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import {
  ADHOC_DOC,
  AroDocs,
  AROViewModel,
  BASIC_PUS_DOC,
  BUSINESS_PUS_DOC,
  DrivingLicense_DOC,
  FirearmInstructorDocs,
  ID_DOC,
  KOA_DOC,
  PsiraRegistrationDocuments,
  SapsCompDoc,
  TacticalKnowledgeDoc,
} from "models";
import store from "@/store";
import dayjs from "dayjs";
import logger from "@/plugins/logger";

@Module({
  dynamic: true,
  namespaced: true,
  name: "Documents",
  store,
})
class Documents extends VuexModule {
  aros: AROViewModel[] = [];
  selectedAro: AROViewModel | null = null;
  aroDocs: AroDocs | null = null;

  /* Documentation Steps
   *
   * This is used to jump between the various documentation steps.
   *
   */
  private currentStep = -1;
  get getCurrentStep() {
    return this.currentStep;
  }
  @Mutation
  setCurrentStep(val: number) {
    this.currentStep = val;
  }

  get getAros(): AROViewModel[] {
    return this.aros;
  }
  @Mutation
  setAros(val: AROViewModel[]) {
    this.aros.splice(0);
    this.aros = val;
  }
  get getAro(): AROViewModel | null {
    return this.selectedAro;
  }
  @Mutation
  setAro(val: AROViewModel | null) {
    this.selectedAro = val;
  }
  // DEPRECATED: These methods are not being called by any other code.
  // @Mutation
  // addAro(val: AROViewModel) {
  //   this.aros.push(val);
  // }
  // @Mutation
  // remAro(val: AROViewModel) {
  //   const pos = this.aros.findIndex((e) => e.id == val.id);
  //   if (pos != -1) {
  //     this.aros.splice(pos, 1);
  //   }
  // }

  get getAroDocs() {
    return this.aroDocs;
  }
  @Mutation
  setAroDocs(val: AroDocs | null) {
    this.aroDocs = val;
  }

  get getUrgentDocs(): {
    dls: DrivingLicense_DOC[];
    firearmInstructorDocs: FirearmInstructorDocs[];
    sapsCompCerts: SapsCompDoc[];
    psiraDocs: PsiraRegistrationDocuments[];
  } {
    logger.log("Urgent Documents");
    if (this.getAroDocs != null) {
      return {
        dls: this.getAroDocs.drivingLicenses.filter((e) => {
          if (e.isRenewed == false) {
            const currDate = dayjs();
            const expDate = dayjs(e.expiryDate);

            if (expDate.diff(currDate, "days") <= 30) {
              // logger.warn("Same month");
              return true;
            } else {
              // logger.warn("No month diff but same year");
              return false;
            }
          } else {
            return false;
          }
        }),
        firearmInstructorDocs: this.getAroDocs.instructorUnitStandards.filter(
          (e) => {
            const currDate = dayjs();
            const expDate = dayjs(e.expiryDate);
            if (expDate.diff(currDate, "days") <= 150) {
              // logger.warn("Same month");
              return true;
            } else {
              // logger.warn("No month diff but same year");
              return false;
            }
          }
        ),
        psiraDocs: this.getAroDocs.psiraRegistrationDocuments.filter((e) => {
          if (e.isRenewed == false) {
            const currDate = dayjs();
            const expDate = dayjs(e.expiryDate);

            logger.log(
              "PSIRA Urgency",
              currDate,
              expDate,
              expDate.diff(currDate, "days")
            );

            if (expDate.diff(currDate, "days") <= 90) {
              // logger.warn("Same month");
              return true;
            } else {
              // logger.warn("No month diff but same year");
              return false;
            }
          } else {
            return true;
          }
        }),
        sapsCompCerts: this.getAroDocs.sapsCompetencyCertificates.filter(
          (e) => {
            if (e.isRenewed == false) {
              const currDate = dayjs();
              const expDate = dayjs(e.expiryDate);
              if (e.isRegulation21Certificate == false) {
                if (expDate.diff(currDate, "days") <= 150) {
                  // logger.warn("Same month");
                  return true;
                } else {
                  // logger.warn("No month diff but same year");
                  return false;
                }
              } else {
                if (expDate.diff(currDate, "days") <= 60) {
                  // logger.warn("Same month");
                  return true;
                } else {
                  // logger.warn("No month diff but same year");
                  return false;
                }
              }
            } else return false;
          }
        ),
      };
    }
    return {
      dls: [],
      sapsCompCerts: [],
      firearmInstructorDocs: [],
      psiraDocs: [],
    };
  }

  get getRenewedDocs(): {
    psira: PsiraRegistrationDocuments[];
    dls: DrivingLicense_DOC[];
    basicPus: KOA_DOC[];
    sapsCompCerts: SapsCompDoc[];
  } {
    if (this.getAroDocs != null) {
      return {
        psira: this.getAroDocs.psiraRegistrationDocuments.filter(
          (e) => e.isRenewed
        ),
        dls: this.getAroDocs.drivingLicenses.filter((e) => e.isRenewed),
        basicPus: this.getAroDocs.basicFirearmProficiencies.filter(
          (e) => e.isRenewed
        ),
        sapsCompCerts: this.getAroDocs.sapsCompetencyCertificates.filter(
          (e) => e.isRenewed
        ),
      };
    }

    return {
      psira: [],
      dls: [],
      basicPus: [],
      sapsCompCerts: [],
    };
  }

  get getCompletedDocs(): {
    adhoc: number;
    koa: number;
    basicPUS: number;
    businessPUS: number;
    driversLic: number;
    idDoc: number;
    firearmInstructionUS: number;
    psiraRegDocs: number;
    sapsComp: number;
    tacticalKnowledge: number;
    reg21: number;
    tacticalUS: number;
    rangeOfficer: number;
  } {
    if (this.getAroDocs != null) {
      return {
        rangeOfficer: this.getAroDocs.rangeOfficerUnitStandards.length,
        adhoc: this.getAroDocs.adhocTrainingDocuments.length,
        koa: this.getAroDocs.basicFirearmProficiencies.length,
        basicPUS: this.getAroDocs.basicProficiencyCertificates.filter(
          (x) => x.isTacticalUnitStandards == false
        ).length,
        businessPUS: this.getAroDocs.businessProficiencyCertificates.length,
        driversLic: this.getAroDocs.drivingLicenses.filter(
          (x) => !this.getUrgentDocs.dls.includes(x)
        ).length,
        idDoc: this.getAroDocs.idDocuments.length,
        firearmInstructionUS: 0,
        psiraRegDocs: this.getAroDocs.psiraRegistrationDocuments.filter(
          (x) => !this.getUrgentDocs.psiraDocs.includes(x)
        ).length,
        sapsComp: this.getAroDocs.sapsCompetencyCertificates.filter(
          (x) =>
            x.isRegulation21Certificate == false &&
            !this.getUrgentDocs.sapsCompCerts.includes(x)
        ).length,
        reg21: this.getAroDocs.sapsCompetencyCertificates.filter(
          (x) =>
            x.isRegulation21Certificate == true &&
            !this.getUrgentDocs.sapsCompCerts.includes(x)
        ).length,
        tacticalKnowledge: this.getAroDocs.tacticalKnowledge.length,
        tacticalUS: this.getAroDocs.basicProficiencyCertificates.filter(
          (x) => x.isTacticalUnitStandards == true
        ).length,
      };
    } else {
      return {
        rangeOfficer: 0,
        reg21: 0,
        adhoc: 0,
        koa: 0,
        basicPUS: 0,
        businessPUS: 0,
        driversLic: 0,
        idDoc: 0,
        firearmInstructionUS: 0,
        psiraRegDocs: 0,
        sapsComp: 0,
        tacticalKnowledge: 0,
        tacticalUS: 0,
      };
    }
  }

  get getOutstandingDocs(): {
    // adhoc: boolean;
    koa: boolean;
    basicPus: boolean;
    businessPus: boolean;
    // driversLic: boolean;
    reg21: boolean;
    // idDoc: boolean;
    // firearmInstructionUS: boolean;
    psiraRegDocs: boolean;
    sapsComp: boolean;
    // tacticalUS: boolean;
    // rangeOfficer: boolean;
  } {
    if (this.getAroDocs != null) {
      return {
        // tacticalUS:
        //   this.getAroDocs.basicProficiencyCertificates.filter(
        //     (e) => e.isTacticalUnitStandards == true
        //   ).length == 0,

        // rangeOfficer: this.getAroDocs.rangeOfficerUnitStandards.length == 0,
        psiraRegDocs: this.getAroDocs.psiraRegistrationDocuments.length == 0,
        koa: this.getAroDocs.basicFirearmProficiencies.length == 0,
        basicPus:
          this.getAroDocs.basicProficiencyCertificates.filter(
            (e) => e.isTacticalUnitStandards == false
          ).length == 0,
        businessPus:
          this.getAroDocs.businessProficiencyCertificates.length == 0,
        // driversLic: this.getAroDocs.drivingLicenses.length == 0,
        // idDoc: this.getAroDocs.idDocuments.length == 0,
        // firearmInstructionUS:
        //   this.getAroDocs.instructorUnitStandards.length == 0,

        sapsComp: this.getAroDocs.sapsCompetencyCertificates.length == 0,
        reg21:
          this.getAroDocs.sapsCompetencyCertificates.find(
            (e) => e.isRegulation21Certificate == true
          ) == null,
        // adhoc: this.getAroDocs.adhocTrainingDocuments.length == 0,
      };
    }
    return {
      // tacticalUS: true,
      // rangeOfficer: true,
      reg21: true,
      // adhoc: true,
      koa: true,
      basicPus: true,
      businessPus: true,
      // driversLic: true,
      // idDoc: true,
      // firearmInstructionUS: true,
      psiraRegDocs: true,
      sapsComp: true,
    };
  }
  get getAroDocsBreakdown() {
    return {
      urgent: this.getUrgentDocs,
      completed: this.getCompletedDocs,
      isRenewed: this.getRenewedDocs,
      outstanding: this.getOutstandingDocs,
    };
  }
}
export default getModule(Documents);
