
import logger from "@/plugins/logger";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FileMixin extends Vue {
  viewFileInTab(file: File | null): void {
    if (file != null) {
      // const temp = URL.createObjectURL(file);
      logger.log("File stuff ", file);

      const temp = URL.createObjectURL(file);
      if (temp != null) {
        //   logger.log("View image / pdf ", temp);
        // this.view.image = temp;
        // this.view.isView = true;
        // const tab = window.open();
        // if (tab) tab.location.href = temp;
        window.open(temp, "_blank");
      } else {
        logger.warn("No image created");
      }
    }
  }
}
