
import { Component, Vue, Watch } from "vue-property-decorator";
import Auth from "@/hooks/Authenticate";
import AuthModule from "@/store/modules/Auth";
import Recaptcha2 from "@/components/Recaptcha2.vue";
import { Storage, Notifications } from "@/hooks";
import { generateMessageFromError } from "@/plugins/axios";
import logger from "@/plugins/logger";
import { Device } from "@capacitor/device";
import NotificationSystem from "@/store/modules/NotificationSystem";
import fbHook from "@/hooks/firebase";
import { storageLib } from "@/hooks/storage";

@Component({
  components: {
    Recaptcha2,
  },
})
export default class Login extends Vue {
  forgotPasswordModal = false;
  valid = false;
  captcha = false;
  username: string | null = null;
  password: string | null = null;
  supportedBrowsers = [
    {
      name: "Chrome",
    },
    {
      name: "Safari on IOS",
    },
  ];
  error = {
    open: false,
    msg: "",
  };
  errorMsg = "Error Logging in: Please try again...";
  visiblePass = false;
  validReset = false;
  /*  validResetDesc = false; */
  resetBusy = false;
  forgotPassEmail = null;
  /*  forgotPass = null;
  forgotConfirmPass = null;
  forgotPassDesc = "";
  visible = { pass: false, confirm: false }; */
  loading = false;
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
    /*  matchingPasswords: (value: string): boolean | string =>
      this.checkPasswordsMatch(value) || "Passwords do not match", */
  };

  /* checkPasswordsMatch(pass: string): boolean {
    if (pass == this.forgotPass) {
      return true;
    } else {
      return false;
    }
  }*/

  /* @Watch("validResetDesc", { immediate: true })
  emailValid(): void {
    if (this.validResetDesc == true) {
      this.forgotPassDesc = " and new Password";
    } else {
      this.forgotPassDesc = "";
    }
  } */

  get getCaptcha(): boolean {
    return true;
  }

  get displayReCaptcha(): boolean {
    if (process.env.VUE_APP_MODE == "production") {
      this.captcha = true;
      return false;
    } else {
      return true;
    }
  }

  async submitForgotEmail(): Promise<void> {
    this.validReset == true;
    try {
      this.resetBusy = true;
      const res = await Auth.resetPassword(
        this.forgotPassEmail /* ,
          this.forgotPass,
          this.forgotConfirmPass */
      ).catch((err: any): Promise<void> => {
        logger.log("Reset Pass Err", err);
        this.validReset = false;
        this.error = {
          open: true,
          msg: generateMessageFromError(err),
        };
        this.errorMsg = err.data.message;
        this.forgotPasswordModal = false;

        return Promise.reject(err);
      });
      this.validReset = false;
      this.$notifCreator.createSuccessNotification(
        "Reset Link Sent to Your Email Address. (If email not found check Junk or Spam folder)"
      );
      this.forgotPasswordModal = false;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.forgotPassEmail = null;
      this.resetBusy = false;
    }
  }

  async login(): Promise<void> {
    try {
      this.loading = true;
      if (this.username == null || this.password == null)
        return Promise.reject(
          "Both the USERNAME and their PASSWORD are required"
        );

      const id = await Device.getId();
      if (!id) {
        Notifications.createErrorNotification("Device ID not detected");
      }
      const res = await Auth.login({
        username: this.username.toLowerCase(),
        password: this.password,
        deviceId: id.uuid,
      });
      AuthModule.setUser(res);
      await Storage.setLogin(res);
      // const resRoles = await Auth.getRoles();
      // let userRole = await Auth.getUser();
      // AuthModule.setFirearmProxy(userRole.manageUser?.assignFirearmProxy);
      // AuthModule.setincidentProxy(userRole.manageUser?.incidentProxy);
      // AuthModule.setReportProxy(userRole.manageUser?.reportingProxy);
      // AuthModule.setUserRoles(resRoles);
      await Auth.getUser();
      if (NotificationSystem.getDeviceInfo?.platform == "web") {
        console.log("Test fcm login  ", NotificationSystem.getFirebaseToken);
        const token = await fbHook.getPWAToken();

        if (token != null) {
          await storageLib.setFcmToken(token);
          NotificationSystem.setFirebaseToken(token);

          await Notifications.doPushNotifications(token);
        }
      } else {
        if (NotificationSystem.getFirebaseToken) {
          await Notifications.doPushNotifications(
            NotificationSystem.getFirebaseToken
          );
        } else {
          this.$notifCreator.createWarningNotification(
            "No token detected on login"
          );
        }
      }

      if (
        AuthModule.getUserOverview!.roleID == "4" ||
        AuthModule.getUserOverview!.roleID == 4
      ) {
        await this.$router.push({
          name: "dash.overview",
        });
      } else {
        await this.$router.push({
          name: "dash.quickLinks",
        });
      }
    } catch (err) {
      // this.alert = true;
      // const msg = generateMessageFromError(err);
      this.error = {
        open: true,
        msg: generateMessageFromError(err),
      };
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  passwordRules = [];

  beforeMount(): void {
    this.username = null;
    this.password = null;
  }

  mounted(): void {
    // if (this.$refs.loginForm) {
    //   (this.$refs.loginForm as any).validate();
    // }
    if (this.$route.query.message) {
      // Used to let users know that they have validated their e-mail addresses.
      this.$notifCreator.createSuccessNotification(
        this.$route.query.message as string
      );
    }
  }
}
