
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import { UserOverview } from "userModels";
import { Base64FilesViewModel, userRole } from "models";
import Signature from "@/components/Signature.vue";
import { Authenticate, Documents, Profile as profileHook } from "@/hooks";
import logger from "@/plugins/logger";

@Component({
  components: {
    Signature,
  },
})
export default class Profile extends Vue {
  loading = false;
  signature: {
    b64: string | null;
    clear: boolean;
    type: "add" | "edit";
    show: boolean;
  } = {
    b64: null,
    clear: false,
    show: false,
    type: this.hasSignature ? "edit" : "add",
  };

  get getUsername(): string | null {
    return AuthModule.getUsername;
  }

  get getUserOverview(): UserOverview | null {
    return AuthModule.getUserOverview;
  }

  get getUserRoles(): userRole | null {
    if (AuthModule.getUserRoles && this.getUserOverview) {
      const find = AuthModule.getUserRoles.find(
        (role) => role.roleId == this.getUserOverview!.roleID
      );
      if (find) {
        return find;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  get hasSignature(): boolean {
    if (this.getUserOverview) {
      if (this.getUserOverview.userSignature) {
        if (
          this.getUserOverview.userSignature.dataFiles &&
          this.getUserOverview.userSignature.mimeType
        ) {
          return true;
        } else {
          logger.log("here");
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get getSignaturePreview(): string | null {
    if (this.getUserOverview && this.getUserOverview.userSignature) {
      return `data:${this.getUserOverview.userSignature.mimeType};base64,${this.getUserOverview.userSignature.dataFiles}`;
    } else {
      return null;
    }
  }

  scrollTo() {
    setTimeout(() => {
      const element = document.getElementById("content");
      if (element) element.scrollIntoView({ behavior: "smooth" });
    });
  }

  async handleSignatureUpdate(b64: string): Promise<void> {
    // logger.log("Signature: ", b64);
    if (b64 != undefined) {
      this.signature.b64 = b64;
      await this.updateSignature();
    } else {
      this.$notifCreator.createErrorNotification(
        "Sorry! Something went wrong, Please try later"
      );
    }
  }

  /*  async getFileFromb64(b64: string): Promise<File> {
    const filetype = Documents.detectMimeType(b64);
    const int8Array = await fetch(b64)
      .then((res) => res.arrayBuffer())
      .then((buffer) => new Uint8Array(buffer));
    const file = new File(
      [new Blob([int8Array], { type: filetype })],
      `${
        this.getUserOverview?.name + " " + this.getUserOverview?.surname
      }_signature.png`,
      {
        type: filetype,
      }
    );
    return Promise.resolve(file);
  } */

  async updateSignature(): Promise<void> {
    try {
      this.loading = true;

      if (this.getUserOverview && this.signature.b64) {
        const mimeType = "image/png";
        const fileName = `${
          this.getUserOverview.name + "_" + this.getUserOverview.surname
        }_signature.png`;
        const upload = this.signature.b64.split(",")[1];

        const data: Base64FilesViewModel = {
          fileName: fileName,
          fileSize: upload.length,
          mimeType: mimeType,
          upload: upload,
        };
        const res = await profileHook.UpdateUserSignature(data);
        this.$notifCreator.createSuccessNotification("Signature Updated.");
        this.signature.b64 = null;
        this.signature.clear = true;
        this.signature.show = false;

        await Authenticate.getUser();
        await Authenticate.getRoles();
      }
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.signature.clear = false;
    }
  }
}
