
import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { Incidents, Firearms } from "@/hooks";
import logger from "@/plugins/logger";

@Component({})
export default class manageIncidentModal extends Vue {
  @PropSync("open") manageItem!: boolean;
  @PropSync("refresh") refreshData!: boolean;
  @Prop() readonly data!: {
    type: string;
    field: any;
  };
  valid = false;
  incidentTypes: any[] = [];
  aroSOs: any[] = [];
  firearms: any[] = [];
  ammoCalibre: any[] = [];
  calibres: any[] = [];
  dateMenu = false;
  timeMenu = false;
  ammoAmountDisabled = false;
  ammoSubtractionDisabled = false;
  firearmDischargedDisabled = false;
  showSelectedARO = false;
  aroPSIRARegNo = "";
  aroIDNo = "";
  aroSupervisor = "";
  maxDischargedRounds = 100;
  file: any[] = [];
  images: any[] = [];
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    fileSize: (value: { size: number }): boolean | string =>
      !value || value.size < 50000000 || "Max File Size is 50MB",

    /*  maxRounds: (value: number): boolean | string =>
      value <= this.maxDischargedRounds ||
      `Max of ${this.maxDischargedRounds} discharged rounds allowed.`, */
  };

  @Watch("data.field", { immediate: true })
  async modalDataChanged(): Promise<void> {
    logger.log("This modal fields:", this.data.field);
    await this.getDropDownData();
  }

  /* @Watch("data.field.firearmID", { immediate: true })
  async firearmIDChanged(): Promise<void> {
    logger.log("FirearmID:", this.data.field.firearmID);
    await this.getFirearmByIDForIncident();
  } */

  closeModal(): void {
    this.images = [];
    this.file = [];
    this.showSelectedARO = false;
    this.aroPSIRARegNo = "";
    this.aroIDNo = "";
    this.aroSupervisor = "";
    this.manageItem = false;
  }

  async getDropDownData(): Promise<void> {
    try {
      const res = await Incidents.getIncidentTypeAndAROsFirearms();
      this.incidentTypes = res.incidentTypes;
      this.aroSOs = res.armResponseOfficerList;
      this.firearms = res.firearmList;
      this.calibres = res.calibreList;
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getFirearmByIDForIncident(): Promise<void> {
    this.ammoCalibre = [];

    try {
      const res = await Firearms.getFirearmByIDAsyncForIncident(
        this.data.field.firearmID
      );

      this.ammoCalibre = res.lstAmmunition;
      /* this.maxDischargedRounds = res.ammunition; */
      logger.log("maxrounds", this.maxDischargedRounds);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  showAmmunitionAmount(id: number): void {
    const res = this.incidentTypes.find((i: any) => i.id == id);
    logger.log("res", res.affectsAmmunition);
    if (res.affectsAmmunition == true) {
      this.ammoAmountDisabled = false;
      this.ammoSubtractionDisabled = false;
      this.firearmDischargedDisabled = true;

      /* this.data.field.firearmDischarged = false; */
    } else {
      this.ammoAmountDisabled = true;
      this.ammoSubtractionDisabled = true;
      this.firearmDischargedDisabled = false;

      /* this.data.field.ammoAmount = 0;
      this.data.field.ammunitionSubtractionID = 0; */
    }
  }

  showSelectedAROInfo(id: number): void {
    this.aroSOs.forEach((elem: any) => {
      if (elem.id == id) {
        this.aroPSIRARegNo = elem.psiraNumber;
        this.aroIDNo = elem.idNumber;
        this.aroSupervisor = elem.supervisor;
      }
    });
    this.showSelectedARO = true;
  }

  async addItem(): Promise<void> {
    logger.log("Add Incident info: ", this.data.field);

    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem) => {
      if (elem == "firearmDischarged") {
        this.data.field["firearmDischarged"] == null
          ? (this.data.field["firearmDischarged"] = false)
          : this.data.field["firearmDischarged"];
      }
      if (elem == "sapsCaseOpened") {
        this.data.field["sapsCaseOpened"] == null
          ? (this.data.field["sapsCaseOpened"] = false)
          : this.data.field["sapsCaseOpened"];
      }

      formdata.append(elem, this.data.field[elem]);
    });
    formdata.append("datepicker", this.data.field["date"]);
    formdata.append("timePicker", this.data.field["time"]);
    for (let i = 0; i < this.file.length; i++) {
      formdata.append("file", this.file[i]);
    }
    for (let i = 0; i < this.images.length; i++) {
      formdata.append("images", this.images[i]);
    }

    try {
      const res = await Incidents.addIncident(formdata);
      this.$notifCreator.createSuccessNotification(
        "Incident Added Succesfully!"
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.closeModal();
    }
  }

  async editItem(): Promise<void> {
    logger.log("Edit Incident info: ", this.data.field);

    var formdata = new FormData();
    Object.keys(this.data.field).forEach((elem) => {
      /* if (elem == "firearmDischarged") {
        this.data.field["firearmDischarged"] == null
          ? (this.data.field["firearmDischarged"] = false)
          : this.data.field["firearmDischarged"];
      }
      if (elem == "sapsCaseOpened") {
        this.data.field["sapsCaseOpened"] == null
          ? (this.data.field["sapsCaseOpened"] = false)
          : this.data.field["sapsCaseOpened"];
      } */
      if (this.data.field[elem] != null) {
        formdata.append(elem, this.data.field[elem]);
      }
    });
    formdata.append("datepicker", this.data.field["date"]);
    formdata.append("timePicker", this.data.field["time"]);
    /* formdata.append("file", this.file); */
    /* formdata.append("images", this.images); */
    try {
      const res = await Incidents.editIncident(formdata);
      this.$notifCreator.createSuccessNotification(
        "Incident Edited Succesfully!"
      );
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.refreshData = true;
      this.closeModal();
    }
  }
}
