
import { Component, PropSync, Vue } from "vue-property-decorator";
import OModal from "@/views/dashboard/overview/Modal.vue";
import { Dash } from "@/hooks";
import logger from "@/plugins/logger";

@Component({
  components: {
    OModal,
  },
})
export default class Other extends Vue {
  @PropSync("loading") load!: boolean;
  overviewModal = false;
  psiraRenewedHeaders = [
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Name", value: "aroName" },
    { text: "PSIRA Reg No", value: "registrationNumber" },
    { text: "Grade", value: "grade" },
    { text: "Expiry Date", value: "expiryDate" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
  ];
  firearmRenewedHeaders = [
    { text: "Make", value: "make" },
    { text: "Type", value: "typeStr" },
    { text: "Expiry Date", value: "expiryDateStr" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
    { text: "Currently Booked Out", value: "bookedBy" }, // TODO: DEPRECATED
  ];
  sapsRenewedHeaders = [
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Name", value: "aroName" },
    { text: "Firearm Type", value: "firearmType" },
    { text: "Expiry Date", value: "expiryDate" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
  ];
  drivingRenewedHeaders = [
    { text: "ARO/SO", value: "isSecurityGuard" },
    { text: "Name", value: "aroName" },
    { text: "Expiry Date", value: "expiryDateStr" },
    { text: "Days to Expiry", value: "daysToExpiryCalc" },
    { text: "File", value: "imagePath" },
  ];
  td: any[] = [];
  modalData = {
    tableData: this.td,
    tableHeaders: this.psiraRenewedHeaders,
    tableName: "",
    title: "",
    sortBy: "",
  };

  async openModal(i: {
    color: string;
    icon: string;
    title: string;
    value: number;
    name: string;
    colorCheck: string;
  }): Promise<void> {
    this.load = true;
    this.modalData.title = i.title;

    if (i.name == "psiraRenewed") {
      try {
        const res = await Dash.GetPSIRAListRenewal(1);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.psiraRenewedHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "firearmRenewed") {
      try {
        const res = await Dash.GetFirearmListRenewal(2);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.firearmRenewedHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "sapsRenewed") {
      try {
        const res = await Dash.GetSAPSListRenwal(3);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.sapsRenewedHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    } else if (i.name == "drivingRenewed") {
      try {
        const res = await Dash.GetDrivingLicenseRenewal(9);
        this.modalData.tableData = res;
        this.modalData.tableData.forEach((item, i) => {
          item.id = i + 1;
        });
        this.modalData.tableHeaders = this.drivingRenewedHeaders;
        this.modalData.tableName = i.name;
        this.modalData.sortBy = "expiryDate";
      } catch (err) {
        return Promise.reject(err);
      }
    }
    this.load = false;
    this.overviewModal = true;
  }

  colorEval(str: string): string {
    logger.log(str);
    return eval(str);
  }
  async mounted(): Promise<void> {
    try {
      this.load = true;
      const res = await Dash.GetDashboardCounts();
      this.dash.forEach((el) => {
        const index = Object.keys(res).find((i: string) => i == el.name);
        if (index != undefined) {
          el.value = res[index];
        }
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.load = false;
    }
  }
  dash: {
    color: string;
    icon: string;
    title: string;
    value: number;
    name: string;
    colorCheck: string;
  }[] = [
    /* {
      color: "teal lighten-1",
      icon: "mdi-autorenew",
      title: "PSIRA Registrations Renewals Submitted",
      value: -1,
      name: "psiraRenewed",
      colorCheck: "<= 0",
    }, */
    {
      color: "teal lighten-1",
      icon: "mdi-autorenew",
      title: "Firearms Renewals Submitted",
      value: -1,
      name: "firearmRenewed",
      colorCheck: "<= 0",
    },
    {
      color: "orange lighten-2",
      icon: "mdi-autorenew",
      title: "SAPS Comptency Renewals Submitted",
      value: -1,
      name: "sapsRenewed",
      colorCheck: "<= 0",
    },
    /* {
      color: "orange lighten-2",
      icon: "mdi-autorenew",
      title: "Number of Firearm related incidents in the past 90 days",
      value: -1,
      name: "regulationRenewed",
      colorCheck: "> 0",
    }, */
    {
      color: "orange lighten-2",
      icon: "mdi-autorenew",
      title: "Driving License Renewals Submitted",
      value: -1,
      name: "drivingRenewed",
      colorCheck: "<= 0",
    },
  ];
}
