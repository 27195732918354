
import { Component, Vue } from "vue-property-decorator";
import JsonExcel from "@/components/JsonExcel.vue";
import { ActivityLog, User, Downloader } from "@/hooks";
import { ActivityLogModel, ManageUserViewModel } from "models";
import dayjs from "dayjs";
import NotificationSystem from "@/store/modules/NotificationSystem";

@Component
export default class NotificationList extends Vue {
  get notifications() {
    return NotificationSystem.apiNotitications;
  }
  get unreadNotifications() {
    return NotificationSystem.unreadNotitications;
  }
  async loadNotifications() {
    await NotificationSystem.getNotificationFromApi();
  }
  async mounted() {
    await this.loadNotifications();
  }
}
